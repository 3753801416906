import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import trackEditor from '../views/trackEditor.vue'
import dashboardview from '../views/dashboardview.vue'
import login from '../views/login.vue'
import * as me  from '../store/modules/me.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: dashboardview
  },
  {
    path: '/editor',
    name: 'trackEditor',
    component: trackEditor
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/',
    name: 'init',
    component: dashboardview
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
    let token = (me.state.jwt)
    // if(!token){
    //   token = localStorage.getItem('t')
    //   console.log(me.state.jwt)
    // }
    let my_routes = []
    for(let i in routes){
      let route = routes[i]
      my_routes.push(route.path)
    }
		if (token) {
      if (to.path != '/login' && my_routes.includes(to.path)) {
        next();
      } else {
        next('/dashboard');
      }
		} 
    else {
      if (to.path == '/login') {
        next();
      } else {
        next('/login');
      }
		}
});

export default router
