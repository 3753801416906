import axios from 'axios'
import jwt_decode from 'jwt-decode'
import {client, setHeaders} from "@/api/client";
import router from '@/router'


export const namespaced = true

export const state = {
    jwt: localStorage.getItem('t'),
    endpoints: {
      obtainJWT: process.env.VUE_APP_API_ENDPOINT+'/auth/obtain_token/',
      refreshJWT: process.env.VUE_APP_API_ENDPOINT+'/auth/refresh_token/'
    },
    userData: undefined,
}
export const mutations = {
    updateToken(state, newToken){
        localStorage.setItem('t', newToken);
        state.jwt = newToken;
    },
    removeToken(state){
        localStorage.removeItem('t');
        state.jwt = null;
    },
    SET_USER_DATA(state, data){
        state.userData = data
    }
}
export const actions = {
    async obtain_token({state,commit},{username, password}){
        const payload = {
            username: username,
            password: password
        }
        let response = await axios.post(state.endpoints.obtainJWT, payload)
        commit('updateToken', response.data.token);
        let headers = {'Authorization':'JWT ' + response.data.token}
        setHeaders(headers)
        
    },
    refreshToken(){
        const payload = {
            token: state.jwt
        }
        axios.post(state.endpoints.refreshJWT, payload)
            .then((response)=>{
                this.commit('updateToken', response.data.token)
            })
            .catch((error)=>{
                console.error(error)
            })
        },
    inspectToken(){
        const token = this.state.jwt;
        if(token){
          const decoded = jwt_decode(token);
          const exp = decoded.exp
          const orig_iat = decoded.orig_iat
          if(exp - (Date.now()/1000) < 1800 && (Date.now()/1000) - orig_iat < 628200){
            this.dispatch('refreshToken')
          } else if (exp -(Date.now()/1000) < 1800){
            // DO NOTHING, DO NOT REFRESH          
          } else {
            // PROMPT USER TO RE-LOGIN, THIS ELSE CLAUSE COVERS THE CONDITION WHERE A TOKEN IS EXPIRED AS WELL
          }
        }
    },
    getUserData({commit}){
        return new Promise((resolve)=> {   
            if(state.userData){
                resolve()
            }else{
                client.get('api/get_user_data').then((response)=>{
                    //console.log(['Administrador','Escritura'].includes(response.data.group))
                    commit('SET_USER_DATA', response.data)
                    resolve()
                })
                .catch((error)=>{
                    console.error(error)
                    commit('removeToken')
                    router.replace({name: 'login'}, () => {
                        window.location.reload();
                    });
                })
            }
        })
    },
    logOut({commit}){
        commit('removeToken')
        router.replace({name: 'login'}, () => {
            window.location.reload();
        });
    }
}

export const getters = {
    userData: (state) => () => {
        return state.userData
    },
}
