<template>
    <div class="modal" >
            <v-row>
                <v-col cols='7'>
                <h1>{{title}} </h1>
                </v-col>
                <v-col cols='5'>
                <v-btn v-if="type=='edit'" style=" left:-10%;backgroundColor:#C70039" @click="del" >Eliminar nota</v-btn>
                </v-col>
            </v-row>
            <br>
            <v-row align="center" no-gutters>
                <v-col cols='2'>
                    <b >Texto:</b>
                </v-col>
                <v-col cols="10">
                    <v-text-field 
                        v-model="text"
                        >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row align="center">
                <v-col cols='6'>
                    <b>Color Texto Nota:</b>
                </v-col>
                <v-col cols='1'>
                    <div :style="{height:'2.3vh',width:'2.3vh',backgroundColor:txtColor,border:'2px solid gray'}">
                    </div>
                </v-col>
                 <v-col cols='5'>
                    <v-autocomplete  v-model="txtColor" :items="colors"
                        item-text="name"
                        item-value="value">
                    </v-autocomplete>
                </v-col>           
            </v-row>
            <v-row align="center">
                <v-col cols='6'>
                    <b>Color Fondo Nota:</b>
                </v-col>
                 <v-col cols='1'>
                    <div :style="{height:'2.3vh',width:'2.3vh',backgroundColor:bckColor,border:'2px solid gray'}">
                    </div>
                </v-col>
                 <v-col cols='5'>
                    <v-autocomplete  v-model="bckColor" :items="colors" 
                        item-text="name"
                        item-value="value"> 
                    </v-autocomplete>
                </v-col>           
            </v-row>
            <v-row>
                <v-checkbox
                v-model="visible"
                label="Visible en pizarra"
                hide-details
                ></v-checkbox>        
            </v-row>
            <v-row>
                <v-col cols='8'>
                    <v-btn @click="cancel" style="backgroundColor:#D5D5D5">Cancelar</v-btn>
                </v-col>
                
                 <v-col cols='4' >
                    <v-btn @click="save" style="backgroundColor:#78B049">Guardar</v-btn>
                </v-col>
            </v-row>
    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';
export default {
    data(){
        return{
            //opacity:1,
            //zind: 3,
            text:'',
            coor:[0,0],
            type: '',
            id: '',
            title:'',
            txtColor: 'black',
            bckColor: 'white',
            colors:[{value:'transparent',name:'Sin color'}, {value:'darksalmon',name:'Salmón'}
                    ,{value:'red',name:'Rojo'},{value:'blue',name:'Azul'},
                    {value:'green',name:'Verde'},{value:'white',name:'Blanco'}
                    ,{value:'black',name:'Negro'}],
            visible: false,
        }
    },
    computed:{
        ...mapFields('notes',['notes','clickedNote','notesCreated','new_note']),
    },
    methods:{
        cancel(){
            this.new_note = {}
            this.clickedNote= []
        },
        save(){
            if(this.type == 'edit'){
                this.notes[this.id].text = this.text
                this.notes[this.id].bckColor = this.bckColor
                this.notes[this.id].txtColor = this.txtColor
                this.notes[this.id].visible = this.visible
                this.updateNote(this.id)
                this.clickedNote= []
            }
            else{
                if(this.text){
                    this.new_note = {text:this.text, coor:this.coor, bckColor:this.bckColor,txtColor:this.txtColor, visible:this.visible}
                    this.notesCreated += 1
                    this.createNote().then(()=>{
                        this.$emit('savenote')
                        this.new_note = {}
                        this.clickedNote= []
                    })
                }
                else{
                    this.new_note = {}
                    this.clickedNote= []
                }
                
            }
        },
        del(){
            if(this.type == 'edit'){
                this.deleteNote(this.id).then(()=>{
                    this.$emit('savenote')
                    this.clickedNote= []
                })
                
            }
            else{
                this.new_note = {}
                this.clickedNote= []
            }
                
            
        },
        ...mapActions('notes',['deleteNote','updateNote','createNote'])
    },
    mounted() {
        this.type = this.clickedNote[0].type
        if(this.type == 'edit'){
            let key = this.clickedNote[0]['id']
            this.text = this.notes[key].text
            this.coor = this.notes[key].coor
            this.bckColor = this.notes[key].bckColor
            this.txtColor = this.notes[key].txtColor
            this.id = key
            this.title = 'Editar nota'
            this.visible = this.notes[key].visible
        }
        else{
            this.text = ''
            this.coor = this.clickedNote[0].coor
            this.title = 'Nueva nota'
        }
        
    },
}
</script>

<style scoped>

.modal {
 position: fixed;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
 z-index: 10;
 
 width: 100%;
 max-width: 400px;
 background-color: #FFF;
 border-radius: 16px;
 border:7px solid rgb(66, 66, 66);
 
 padding: 25px;
}
</style>
