
<template>
  <div>
    <main >
      <v-progress-circular
                        style="height:7vh;width:7vh;position:fixed;top:12%;right:8%;zIndex:20" 
                        v-if="loading"
                        indeterminate
                        class="mr-2"              
                        color="purple"
                    ></v-progress-circular>
      <!-- <v-btn v-if="userData() && ['Administrador','Escritura'].includes(userData()['group'])" @click="zoomIn" style="position:fixed;top:200px;right:0px;zIndex:20">ZOOM</v-btn> -->
      <div v-if="loading || (userData() && ['Pizarra','Lectura'].includes(userData()['group']))" :style="{backgroundColor: 'none',height:'100vh',width:'100vw',zIndex: 99,position:'absolute'}"
      draggable="false"
      ></div>
      <div ref="scrollable" @dblclick="addNote($event)" :style="{position:'relative',minHeight:'100vh',minWidth:'100vw',overflowX:'scroll',overflowY:'scroll'}" >
        
        <div v-if="draggNote.length" :style="{backgroundColor: 'none',height:imgHeight.toString()+'px',width:imgWidth.toString()+'px',zIndex: 3,position:'absolute'}"
              @drop.prevent="dropNote" @dragover.prevent
              ></div>
        
        <div v-if="clickedNote.length || clickedTrain.length" :style="{backgroundColor: 'rgba(0,0,0,0.3)',height:imgHeight.toString()+'px',width:imgWidth.toString()+'px',zIndex: 4,position:'absolute'}"
              @click="hidePopUp"
              ></div>
      
        <NotePopUp v-if="clickedNote.length" @savenote="calc_coords"></NotePopUp>
        <TrainPopUp v-if="clickedTrain.length"></TrainPopUp>
      
        <div :style="{backgroundColor: 'rgba(0,0,0,0.15)',height:imgHeight.toString()+'px',width:imgWidth.toString()+'px',zIndex: 0,position:'absolute'}"
              ></div>
        <img v-if="map_img" ref="fondo" :src="map_img" @load="loaded"  draggable="false"
        :style="{position:'absolute',width:zoom_state, height:'auto'}"/>
        
        <div style="position:relative;" >
          <Track v-for="(key,i) in Object.keys(scaledTrackPos)" :id="key" :key="i" 
            :style="{ top: scaledTrackPos[key]['top'],left:scaledTrackPos[key]['left'],position:'absolute'}"
            :size="{height:scaledTrackPos[key]['height'],width:scaledTrackPos[key]['width']}"
            @addnote="addNote" draggable="false" :color="'rgba(100, 51, 153,0.05)'"
            >
            <template v-for="(e,j) in trackContent[Object.keys(scaledTrackPos)[i]]"
                 >
              <v-tooltip top :key="j" >
                <template v-slot:activator="{ on }">
                  <Train 
                    :id="e" 
                    :trackHeight="scaledTrackPos[key]['height']" 
                    :tooltip="on"
                    >
                  </Train>
                </template>
                <span v-if="Object.keys(trains[e].check_ups).length > 0 "> Orden de trabajo: <b>{{trains[e].check_ups[Object.keys(trains[e].check_ups)[0]].order_num}}</b></span>
              </v-tooltip>
            </template> 
               
          </Track>
          <Note v-for="(key,i) in Object.keys(scaledNotesPos)" :id="key" :key="'k'+i.toString()"
            :style="{top: scaledNotesPos[key]['top'],left:scaledNotesPos[key]['left'],position:'absolute'}" :bckColor="notes[key].bckColor" :txtColor="notes[key].txtColor"
            @deleted="calc_coords"
            :imgHeight="imgHeight">
          </Note>
        </div>
      </div>
       
    
    </main>
    
  
   
  </div>
</template>
<script>
import { mapFields } from 'vuex-map-fields';
import { mapActions, mapGetters } from 'vuex';
//import App from '../App.vue';
import Track from './Track.vue'
import Train from './Train.vue'
import Note from './Note.vue'
import NotePopUp from './NotePopUp.vue'
import TrainPopUp from './TrainPopUp.vue'
import Vue from 'vue'

export default {
  data(){
    return{
      imgHeight:0,
      imgWidth:0,
      topMargin:0,
      scaledTrackPos: {},
      scaledNotesPos: {},
      showNotePop:true,
      time: '0',
      zoom_state:'100%',
      zoom:[],
      zoom_i:0,
    };
  },
  components: {
    Track,
    Train,
    Note,
    NotePopUp,
    TrainPopUp,
    //App,
  },
  methods:{
    loaded(){
      this.calc_coords()
      this.loading = false
    },
    calc_coords(){
      this.imgHeight = this.$refs.fondo.clientHeight;
      this.imgWidth = this.$refs.fondo.clientWidth ;
      let trPosPx = {};
      for( let i in Object.keys(this.trackPos)){
        let key = Object.keys(this.trackPos)[i]
        let track = this.trackPos[key]
        trPosPx[key]={top:this.topTrack(track['top']),left:this.leftTrack(track['left']),
          height:this.heightTrack(track['height']),width:this.widthTrack(track['width'])}
        }
      this.scaledTrackPos = trPosPx
      let ntPosPx = {}
      for( let i in Object.keys(this.notes)){
        let key = Object.keys(this.notes)[i]
        ntPosPx[key]={top:this.topTrack(this.notes[key].coor[1]),left:this.leftTrack(this.notes[key].coor[0])}
      }
      this.scaledNotesPos = ntPosPx
    },
    topTrack(val){
      return parseInt(this.imgHeight*val).toString()+'px';
    },
    leftTrack(val){
      return parseInt(this.imgWidth*val ).toString()+'px';
    },
    heightTrack(val) {
      return parseInt(this.imgHeight*val ).toString()+'px';
    },
    widthTrack(val){
      return parseInt(this.imgWidth*val ).toString()+'px';
    },
    ignorePointer(train){ //no estic segur si s'esta calculant automaticament sempre que cal o si casualment el component s'actualitza per altres raons (potser caldra fer alguna mena de data)
      if(this.dragging.length == 0){
        return "auto";
      }
      else{
        if(this.dragging[0]['train'] == train){
          return "auto";
        }
        else{
          return "none";
        }
      }
    },
    addNote(event){
      let coords = [0,0]
      if(!event.length){
        coords[0] = event.layerX
        coords[1] = event.layerY
      }
      else{
        coords[0] += event[0] + parseInt(this.scaledTrackPos[event[2]]['left'])
        coords[1] += event[1] + parseInt(this.scaledTrackPos[event[2]]['top'])
      }
      this.clickedNote.push({type:'new',coor: [coords[0]/this.imgWidth,coords[1]/this.imgHeight]})
    },
    dropNote(event){
      let dNote = this.draggNote[0]
      this.notes[dNote['note']]['coor'] = [(event.layerX-dNote['coor'][0])/this.imgWidth,(event.layerY-dNote['coor'][1])/this.imgHeight]
      this.updateNote(dNote.note)
      this.calc_coords()
    },
    hidePopUp(){
      this.clickedNote = []
      this.clickedTrain = []
    },
    getTime(){
      let today = new Date();
      this.time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    },
    refreshAllData(){
      this.refresh_all_data()
    },
    zoomIn(){
      let scroll = this.$refs.scrollable
      if(this.zoom_state=='100%'){
        let curr_zoom = this.zoom[this.zoom_i]
        this.zoom_state = parseInt(curr_zoom.factor*100).toString() + '%'
        this.imgHeight *=curr_zoom.factor
        this.imgWidth *=curr_zoom.factor
        let x = parseInt(this.imgWidth*curr_zoom.x - this.imgWidth/(curr_zoom.factor*2))
        let y = parseInt(this.imgHeight*curr_zoom.y - this.imgHeight/(curr_zoom.factor*2))
        this.zoom_i += 1
        if(this.zoom_i == this.zoom.length){
          this.zoom_i = 0
        }
        Vue.nextTick(function () {
          scroll.scrollTo( x,y)
        })
        
      }
      else{
        let index = this.zoom_i - 1
        if(index < 0){
          index = this.zoom.length - 1
        }
        let last_zoom = this.zoom[index]
        this.zoom_state = '100%'
        this.imgHeight /=last_zoom.factor
        this.imgWidth /=last_zoom.factor
        scroll.scrollTo(0,0)
      }
      this.forced_calc_coords()
    },
    forced_calc_coords(){
      let trPosPx = {};
      for( let i in Object.keys(this.trackPos)){
        let key = Object.keys(this.trackPos)[i]
        let track = this.trackPos[key]
        trPosPx[key]={top:this.topTrack(track['top']),left:this.leftTrack(track['left']),
          height:this.heightTrack(track['height']),width:this.widthTrack(track['width'])}
        }
      this.scaledTrackPos = trPosPx
      let ntPosPx = {}
      for( let i in Object.keys(this.notes)){
        let key = Object.keys(this.notes)[i]
        ntPosPx[key]={top:this.topTrack(this.notes[key].coor[1]),left:this.leftTrack(this.notes[key].coor[0])}
      }
      this.scaledNotesPos = ntPosPx
    },
    ...mapActions('notes',['updateNote']),
    ...mapActions('me',['getUserData']),
    ...mapActions(['set_all_data','refresh_all_data','fill_expected_data']),

  },
  
  computed:{
    ...mapFields('trains',['dragging','clickedTrain','trains','check_ups']),
    ...mapFields('tracks',['trackPos','trackContent','trackInfo','clickedTrack']),
    ...mapFields('notes',['notes', 'notesCreated', 'draggNote','clickedNote']),
    ...mapFields(['datetime','zoom_interval','zoom_ins','map_img','loading']),
    ...mapGetters('me',['userData']),
    isNoteDragging(){
      if(this.draggNote.length == 0){
        return '-1'
      }
      else{
        return '4'
      }
    }
  },
  created() {
    window.addEventListener("resize", this.calc_coords);
    this.interval = setInterval(this.getTime, 1000);
  },
  destroyed() {
    window.removeEventListener("resize", this.calc_coords);
    clearInterval(this.interval)
    if( this.userData()['group'] == 'Pizarra'){
      clearInterval(this.interval_refresh)
      clearInterval(this.zoom_in_interval)
    }
  },
  async mounted() {
    this.loading = true
    await this.getUserData()
    await this.set_all_data()
    this.calc_coords()
    if(this.userData() && this.userData()['group'] == 'Pizarra' && this.zoom_interval>0 && Object.keys(this.zoom_ins)){
      this.interval_refresh = setInterval(this.refreshAllData, 60000);
      this.zoom_in_interval = setInterval(this.zoomIn, this.zoom_interval);
      for(let i in Object.keys(this.zoom_ins)){
        if(this.zoom_ins[Object.keys(this.zoom_ins)[i]].active){
          this.zoom.push(this.zoom_ins[Object.keys(this.zoom_ins)[i]])
        }
      }
      this.zoom_i = 0
    }
    
  },
}
</script>



