<template>
    <v-container class="modal" >
            <v-row>
                <v-col cols='6'>
                <h1>{{id}} </h1>
                </v-col>
                <v-col cols='5'>
                <v-btn  style="backgroundColor:red" @click="del" >Eliminar zoom</v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols='6'>
                    <v-text-field  
                    v-model="zoom_name" 
                    label="Nombre"
                    placeholder="Nombre (opcional)"
                    :hide-details="true"
                    ></v-text-field>
                </v-col>
                <v-col cols='6'>
                    <v-text-field  
                    v-model="factor" 
                    label="Factor"
                    placeholder="Factor aumento zoom"
                    :hide-details="true"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-checkbox label="Activo"
                            v-model="active">
                </v-checkbox>
            </v-row>
            
            <br>
            <br>
            Coordenadas: [{{coords[0].toString().slice(0,4)}}, {{coords[1].toString().slice(0,4)}}]
            
               
            

            <v-row>
                <v-col cols='8'>
                    <v-btn @click="cancel">Cancelar</v-btn>
                </v-col>
                
                 <v-col cols='4' >
                    <v-btn @click="save" style="backgroundColor:green">Guardar</v-btn>
                </v-col>
            </v-row>
    </v-container >
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';
export default {
    name:'zoomPopUp',
    data(){
        return{
            type: '',
            id:'',
            zoom_name:'',
            coords:[],
            factor:'2',
            active:true,
        }
    },
    computed:{
        ...mapFields(['zoom_ins','clickedZoom','zoom_interval','new_zoom']),
    },
    methods:{
        cancel(){
            this.clickedZoom = []
        },
        save(){
            if(this.id == 'new_zoom'){
                this.new_zoom.name = this.zoom_name
                this.new_zoom.active = this.active
                if(parseFloat(this.factor.replace(',','.')) && parseFloat(this.factor.replace(',','.')) != 0){
                    this.new_zoom.factor = Math.abs(parseFloat(this.factor.replace(',','.')))
                }
                else{
                    this.new_zoom.factor = 2
                }
                this.addZoom().then(()=>{
                    this.clickedZoom = []
                    this.$emit('updatezoom')
                })
            }
            else{
                if(parseFloat(this.factor.replace(',','.')) && parseFloat(this.factor.replace(',','.')) != 0){
                    this.zoom_ins[this.id].factor = Math.abs(parseFloat(this.factor.replace(',','.')))
                }
                else{
                    this.zoom_ins[this.id].factor = 2
                }
                this.zoom_ins[this.id].active = this.active
                this.zoom_ins[this.id].name = this.zoom_name
                this.updateZoom(this.id).then(()=>{
                    this.clickedZoom = []
                    this.$emit('updatezoom')
                })
            }
            
            
        },
        del(){
            this.deleteZoom(this.id).then(()=>{
                this.clickedZoom= []
                this.$emit('updatezoom')})
        },
        ...mapActions(['deleteZoom','addZoom','updateZoom'])
    },
    created(){
        this.id = this.clickedZoom[0]
        if(this.id != 'new_zoom'){
            this.zoom_name = this.zoom_ins[this.id].name
            this.coords = [this.zoom_ins[this.id].x,this.zoom_ins[this.id].y]
            this.active = this.zoom_ins[this.id].active
            if(this.zoom_ins[this.id].factor){
                this.factor = this.zoom_ins[this.id].factor.toString()
            }
            else{
                this.factor = '2'
            }
        }
        else{
            this.coords = [this.new_zoom.x, this.new_zoom.y]
            this.factor = '2'
            this.active = true
        }
    }
}
</script>

<style scoped>

.modal {
 position: fixed;
 z-index: 10;
 top: 40%;
 left: 40%;
 width: 100%;
 max-width: 400px;
 background-color: #FFF;
 border-radius: 16px;
 
 padding: 25px;
}
</style>
