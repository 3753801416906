<template>
  <div>
    <div style>
      <Sidebar v-if="userData() && ['Administrador','Escritura'].includes(userData()['group'])"></Sidebar>
      <Dashboard></Dashboard>
    </div>
    
  </div>
</template>

<script>
import Dashboard from '../components/Dashboard.vue'
import Sidebar from '../components/Sidebar.vue'
import { mapGetters } from 'vuex';


  export default {
  components: { Dashboard ,
  Sidebar},
  name: 'dashboardview',
  computed:{
        ...mapGetters('me',['userData'])
    },
  }
</script>
