<template>
  <div v-if="userData() && ['Administrador'].includes(userData()['group'])">
    <v-row>
      <v-col cols="2">
        <v-select :items="['track','zoom']" label= "Modo de edición" v-model="mode"></v-select>
      </v-col>
      <v-col cols="2">
        <v-text-field  
        v-if="mode == 'zoom'"
        v-model="zoom_inter" 
        label="Intervalo entre zooms"
        placeholder="(en segundos)"
        :hide-details="true"
        ></v-text-field>
      </v-col>
      <v-col cols="1">
        <v-btn  v-if="mode == 'zoom'" @click="updInt" style="top:20%">
          Actualizar
          </v-btn>
      </v-col>
      <v-col cols="6">
        <div v-if="mode == 'track'">
          Editando vias. 
        </div>
        <div  v-if="mode == 'zoom'">
          Editando zooms. Los puntos indican el centro de estos. 
        </div>
        
      </v-col>
    </v-row>
    <main>
        
        <div>
            <TrackPopUp v-if="clickedTrack.length " @updatetrack="calc_coords"></TrackPopUp>  
            <ZoomPopUp v-if="clickedZoom.length " @updatezoom="calc_coords"></ZoomPopUp> 
            <div v-if="mode=='track'" :style="{backgroundColor: 'rgba(0,0,0,0.2)',height:imgHeight.toString()+'px',width:imgWidth.toString()+'px',zIndex: 3,position:'absolute'}"
                @click="trackCoordIn"
                @mousemove="updateRect"
            ></div>
            <div v-if="mode=='zoom'" :style="{backgroundColor: 'rgba(0,0,0,0.2)',height:imgHeight.toString()+'px',width:imgWidth.toString()+'px',zIndex: 4,position:'absolute'}"
                @click="trackCoordIn"
            ></div>
            <div v-if="clickedTrack.length || clickedZoom.length" :style="{backgroundColor: 'rgba(0,0,0,0.3)',height:imgHeight.toString()+'px',width:imgWidth.toString()+'px',zIndex: 5,position:'absolute'}"
                @click="hidePopUp"
            ></div>
            <img ref="fondo" src="../assets/tallerCerroNegro.svg" @load="calc_coords" style="position:absolute;
            width:100%; height:auto; top:topMargin" draggable="false"/>

            <div v-if="mode=='track'" style="position:relative;" >
                <div v-if="Object.keys(rectMaker).length == 1" :style="temporaryRectStyle"></div>
                <Track v-for="(key,i) in Object.keys(scaledTrackPos)" :id="key" :key="i" 
                    :style="{ top: scaledTrackPos[key]['top'],left:scaledTrackPos[key]['left'],position:'absolute', zIndex: '5'}"
                    :size="{height:scaledTrackPos[key]['height'],width:scaledTrackPos[key]['width']}"
                    color="DarkViolet" draggable="false" :editorMode="true"
                >
                </Track>
            </div>
            <div v-if="mode=='zoom'" style="position:relative;" >
                <template v-for="(key,i) in Object.keys(scaledZoomPos)">
                <div  class="dot" :key="i"
                 :style="{ top: scaledZoomPos[key]['top'],left:scaledZoomPos[key]['left'],position:'absolute', zIndex: '5',backgroundColor:zoomColors[key]}"
                 @click="clickZoom(key)">{{i}}</div>
                 <div  :key="'square'+i.toString()"
                 :style="zoomPreview[key]"
                  ></div>
                </template>
            </div>
        </div>
    </main>
  </div>

</template>
<script>
import { mapFields } from 'vuex-map-fields';
import { mapActions, mapGetters } from 'vuex';
import TrackPopUp from './TrackPopUp.vue';
import ZoomPopUp from './ZoomPopUp.vue';
import Track from './Track.vue'

export default {
  data(){
    return{
      imgHeight:0,
      imgWidth:0,
      topMargin:0,
      scaledTrackPos: {},
      scaledZoomPos: {},
      rectMaker : [],
      temporaryRectStyle: {left:'0px',
                           top: '0px',
                           width:'0px',
                           height:'0px',
                           backgroundColor:'none',
                           position: 'relative'},
      mode:'track',
      zoom_inter:'',
      
    };
  },
  components: {
    Track,
    TrackPopUp,
    ZoomPopUp,
    //App,
  },
  name:'trackEditor',
  methods:{
    calc_coords(){
      this.imgHeight = this.$refs.fondo.clientHeight;
      this.imgWidth = this.$refs.fondo.clientWidth ;
      let trPosPx = {};
      for( let i in Object.keys(this.trackPos)){
        let key = Object.keys(this.trackPos)[i]
        let track = this.trackPos[key]
        trPosPx[key]={top:this.topTrack(track['top']),left:this.leftTrack(track['left']),
          height:this.heightTrack(track['height']),width:this.widthTrack(track['width'])}
        }
      this.scaledTrackPos = trPosPx
      let zmPosPx = {};
      for( let i in Object.keys(this.zoom_ins)){
        let key = Object.keys(this.zoom_ins)[i]
        let zoom = this.zoom_ins[key]
        zmPosPx[key]={top:this.topTrack(zoom['y']),left:this.leftTrack(zoom['x'])}
        }
      this.scaledZoomPos = zmPosPx
    },
    topTrack(val){
      return parseInt(this.imgHeight*val).toString()+'px';
    },
    leftTrack(val){
      return parseInt(this.imgWidth*val ).toString()+'px';
    },
    heightTrack(val) {
      return parseInt(this.imgHeight*val ).toString()+'px';
    },
    widthTrack(val){
      return parseInt(this.imgWidth*val ).toString()+'px';
    },
    trackCoordIn(event){
      let coor = [0,0]
      coor[0] = event.layerX/this.imgWidth
      coor[1] = event.layerY/this.imgHeight
      if(this.mode == 'track'){
        if(this.rectMaker.length == 1){
          this.rectMaker.push(coor)
          let position = {left:Math.min(this.rectMaker[0][0],this.rectMaker[1][0]),
                                      top: Math.min(this.rectMaker[0][1],this.rectMaker[1][1]),
                                      width:Math.abs(this.rectMaker[1][0]-this.rectMaker[0][0]),
                                      height:Math.abs(this.rectMaker[1][1]-this.rectMaker[0][1]),
                                      }
          this.newTrack({position:position})
          this.clickedTrack.push('new_track')
          this.calc_coords()
        }
        else{
          this.rectMaker=[coor]
        }
      }
      else if(this.mode == 'zoom'){
        this.new_zoom['x'] = coor[0]
        this.new_zoom['y'] = coor[1]
        this.new_zoom['name'] = ''
        this.clickedZoom.push('new_zoom')
      }

    },
    updateRect(event){
      let coor = [0,0]
      coor[0] = event.layerX
      coor[1] = event.layerY
      if(this.rectMaker.length == 1){
        let sup_rect = [[this.rectMaker[0][0]*this.imgWidth,this.rectMaker[0][1]*this.imgHeight], coor]
        this.temporaryRectStyle = {left:(Math.min(sup_rect[0][0],sup_rect[1][0])).toString()+ 'px',
                                   top: (Math.min(sup_rect[0][1],sup_rect[1][1])).toString() + 'px',
                                   width:(Math.abs(sup_rect[1][0]-sup_rect[0][0])).toString() + 'px',
                                   height:(Math.abs(sup_rect[1][1]-sup_rect[0][1])).toString() + 'px',
                                   backgroundColor:'red',
                                   position: 'relative',
                                   zIndex: '2'}
      }
      else{
        this.temporaryRectStyle = {left:'0px',
                                   top: '0px',
                                   width:'0px',
                                   height:'0px',
                                   backgroundColor:'none',
                                   position: 'relative'}
      }
    },
    hidePopUp(){
        this.clickedTrack = []
        this.clickedZoom = []
    },
    // goToDash(){
    //   var params = { name: "Dashboard", params: {} };
    //   this.$router.push(params);
    // },
    clickZoom(key){
      this.clickedZoom.push(key)
    },
    updInt(){
      this.updInterval(parseInt(this.zoom_inter))
    },
    ...mapActions('tracks',['newTrack']),
    ...mapActions('me',['getUserData']),
    ...mapActions(['set_all_data','updInterval'])
  },
  
  computed:{
    //{ left: Math.max(parseInt(scaledZoomPos[key]['left'])- imgWidth/(zoom_ins[key].factor*2),0).toString()+ 'px',top:Math.max(parseInt(scaledZoomPos[key]['top'])- imgHeight/(zoom_ins[key].factor*2),0).toString()+'px', height :(imgHeight/zoom_ins[key].factor).toString()+'px' ,width :(imgWidth/zoom_ins[key].factor).toString()+'px' ,  position:'absolute', zIndex: '4',backgroundColor:'rgb(255,0,0,0.5)'}
    zoomColors(){
      let colors = {}
      for(let i in Object.keys(this.zoom_ins)){
        let key = Object.keys(this.zoom_ins)[i]
        if(this.zoom_ins[key].active){
          colors[key] = 'green'
        }
        else{
          colors[key] = 'red'
        }
      }
      return colors
    },
    zoomPreview(){
      let zoom_previews = {}
      for(let i in Object.keys(this.zoom_ins)){
        let key = Object.keys(this.zoom_ins)[i]
        if(Object.keys(this.scaledZoomPos).includes(key)){
          let width = Math.min(this.imgWidth/this.zoom_ins[key].factor, this.imgWidth)
          let height = Math.min(this.imgHeight/this.zoom_ins[key].factor, this.imgHeight)
          let left = Math.min(Math.max(parseInt(this.scaledZoomPos[key]['left'])- this.imgWidth/(this.zoom_ins[key].factor*2),0) , this.imgWidth - width)
          let top = Math.min(Math.max(parseInt(this.scaledZoomPos[key]['top'])- this.imgHeight/(this.zoom_ins[key].factor*2),0),this.imgHeight - height)
          let color = 'rgb(255,0,0,0.2)'
          if(this.zoomColors[key] == 'green'){
            color = 'rgb(0,255,0,0.2)'
          }
          zoom_previews[key] = { left: left.toString()+ 'px', top: top.toString()+'px', 
                                  height :height.toString()+'px' ,width :width.toString()+'px' , 
                                  position:'absolute', zIndex: '3',backgroundColor:color}
        }
      }
      return zoom_previews
    },
    ...mapFields('tracks',['trackPos','trackContent','trackInfo','clickedTrack']),
    ...mapFields(['new_zoom','zoom_ins','clickedZoom','zoom_interval']),
    ...mapGetters('me',['userData'])
  },
  created() {
    window.addEventListener("resize", this.calc_coords);
  },
  destroyed() {
    window.removeEventListener("resize", this.calc_coords);
  },
  async mounted() {
    this.zoom_inter = parseInt(this.zoom_interval/1000)
    await this.getUserData()
    await this.set_all_data()
    this.calc_coords()
  },
}
</script>

<style lang="css" scoped>
.dot {
  transform: translate(-50%,-50%);
  border-radius: 50%;
  width:1.5vw;
  height:1.5vw;
  display: inline-block;
}
</style>