
import { getField, updateField } from 'vuex-map-fields';
import { client } from "@/api/client";
export const namespaced = true

export const state = {

    trains:{},
    trainKinds:{},
    tag_types:{},
    dragging: [],
    clickedTrain: [],
    relevantTrains:[],
}

export const getters = {
    getTrainImg: (state) => (train) => {
      return state.trainKinds[state.trains[train]['kind']]['img']
    },
    getTrainInfo: (state) => (train) => {
      return state.trains[train]
    },
    getField,
}

export const mutations = {
  DRAG_TRAIN(state,payload){
      state.dragging.push(payload)
  },
  CLEAR_DRAG(state){
      state.dragging = []
  },
  SET_DATA(state,{trains, tag_types, train_kinds,relevant_trains}){
    state.trains = trains
    state.trainKinds = train_kinds
    state.tag_types = tag_types
    state.relevant_trains = relevant_trains
    
  },
  UPDATE_TRAIN(state, {id,check_ups,a_date,a_time,d_date,d_time,tags,track_record,incoming}){
    state.trains[id].check_ups = check_ups
    state.trains[id].arrival = {time:a_time,date:a_date}
    state.trains[id].departure = {time:d_time,date:d_date}
    state.trains[id].tags = tags
    state.trains[id].track_record = track_record
    state.trains[id].incoming = incoming
  },
  updateField,
}

export const actions = {
  dragTrain({commit}, {train, track}){
      commit('DRAG_TRAIN', {train, track})
  },
  clearDrag({commit}){
      commit('CLEAR_DRAG')
  },
  get_train_data({dispatch},id){
    client.get('api/trains/'+id+'/').then((response)=>{
      dispatch('set_one_train_data',{id:id,data:response.data})
    })
  },
  set_one_train_data({commit},{id,data}){
    let a_date = null
    let d_date = null
    let a_time = null
    let d_time = null
    if(Object.keys(data).includes('arrival')){
      if(data.arrival){ 
        let a_datetime = new Date(data.arrival)
        let formatting = a_datetime.toLocaleDateString().split('/').reverse()
        formatting[2] = ('0'+ formatting[2]).slice(-2,3)
        formatting[1] = ('0'+ formatting[1]).slice(-2,3)
        a_date = formatting.join('-')
        a_time = ('0'+a_datetime.toLocaleTimeString()).slice(-8,-3)
      }
    }
    else{
      a_date = state.trains[id].arrival.date
      a_time = state.trains[id].arrival.time
    }
    if(Object.keys(data).includes('departure')){
      if(data.departure){
        let d_datetime = new Date(data.departure)
        let formatting = d_datetime.toLocaleDateString().split('/').reverse()
        formatting[2] = ('0'+ formatting[2]).slice(-2,3)
        formatting[1] = ('0'+ formatting[1]).slice(-2,3)
        d_date = formatting.join('-')
        d_time = ('0'+d_datetime.toLocaleTimeString()).slice(-8,-3)
      }
    }
    else{
      d_date = state.trains[id].departure.date
      d_time = state.trains[id].departure.time
    }
    let upd_check_ups = {}
    if(Object.keys(data).includes('check_ups')){
      if(data.check_ups){
        for(let i in Object.keys(data.check_ups)){
          let chup = data.check_ups[i]
          let date = null
          let time = null
          if(chup.datetime_end){
            let datetime = new Date(chup.datetime_end)
            let formatting = datetime.toLocaleDateString().split('/').reverse()
            formatting[2] = ('0'+ formatting[2]).slice(-2,3)
            formatting[1] = ('0'+ formatting[1]).slice(-2,3)
            date = formatting.join('-')
            time = ('0'+datetime.toLocaleTimeString()).slice(-8,-3)
          }
          let datetime_end = {date:date,time:time}
          upd_check_ups[chup.id.toString()] = {check_up_type: chup.check_up_type.toString(), order_num: chup.order_num, done: chup.done,datetime_end:datetime_end}
        }
      }
    }
    else{
      upd_check_ups = state.trains[id].check_ups
    }
    let upd_tags = {}
    if(Object.keys(data).includes('tags')){
      if(data.tags){
        for(let i in Object.keys(data.tags)){
          let tag = data.tags[i] 
          upd_tags[tag.id.toString()] = {tag_type: tag.tag_type.toString(), done: tag.done}
        }
      }
    }
    else{
      upd_tags = state.trains[id].tags
    }
    let upd_track_record = []
    if(Object.keys(data).includes('track_record') ){
      if(data.track_record){
        for(let i in data.track_record){
          let date = null
          let time = null
          if(data.track_record[i].time){
            let datetime = new Date(data.track_record[i].time)
            let formatting = datetime.toLocaleDateString().split('/').reverse()
            formatting[2] = ('0'+ formatting[2]).slice(-2,3)
            formatting[1] = ('0'+ formatting[1]).slice(-2,3)
            date = formatting.join('-')
            time = ('0'+datetime.toLocaleTimeString()).slice(-8,-3)
          }
          data.track_record[i]['datetime'] = {date:date,time:time}
        }
        upd_track_record = data.track_record
      }
    }
    else{
      upd_track_record = state.trains[id].track_record
    }
    let upd_incoming = true
    if(Object.keys(data).includes('incoming') ){
      upd_incoming = data.incoming
    }
    else{
      upd_incoming = state.trains[id].incoming
    }
    commit('UPDATE_TRAIN', {id:id,check_ups:upd_check_ups,a_date:a_date,a_time:a_time,d_date:d_date,d_time:d_time,tags:upd_tags, track_record:upd_track_record,incoming :upd_incoming})
  },
  updateTrain({dispatch},id){
    let train = state.trains[id]
    let arri = null
    let depa = null
    if(train.arrival.date && train.arrival.time){
     arri = new Date(train.arrival.date + 'T' + train.arrival.time + ':00')
    }
    if(train.departure.date && train.departure.time){
     depa = new Date(train.departure.date + 'T' + train.departure.time + ':00')
    }
    let checkups = train.check_ups
    for(let i in Object.keys(checkups)){
      let key = Object.keys(checkups)[i]
      let datetimedic = train.check_ups[key].datetime_end
      if(datetimedic.date && datetimedic.time){
        checkups[key].datetime_end = new Date(datetimedic.date + 'T' + datetimedic.time + ':00')
      }
      else{
        checkups[key].datetime_end = null
      }
    }
    
    client.patch('api/train_update/'+id+'/', {'info_train':{arrival: arri,departure:depa,incoming:train.incoming},check_ups:checkups,tags:train.tags,track_record:train.track_record}).then((response)=>{
      dispatch('set_one_train_data',{id:id,data:response.data})
    })
  },
  setData({commit},{trains, tag_types, train_kinds, relevant_trains}){
    var state_trains = {}
    for(let i in trains){
      let train = trains[i]
      let a_time = null
      let d_time = null
      let a_date = null
      let d_date = null
      if(train.arrival){
        let a_datetime = new Date(train.arrival)
        let formatting = a_datetime.toLocaleDateString().split('/').reverse()
        formatting[2] = ('0'+ formatting[2]).slice(-2,3)
        formatting[1] = ('0'+ formatting[1]).slice(-2,3)
        a_date = formatting.join('-')
        a_time = ('0'+a_datetime.toLocaleTimeString()).slice(-8,-3)
        
      }
      if(train.departure){
        let d_datetime = new Date(train.departure)
        let formatting = d_datetime.toLocaleDateString().split('/').reverse()
        formatting[2] = ('0'+ formatting[2]).slice(-2,3)
        formatting[1] = ('0'+ formatting[1]).slice(-2,3)
        d_date = formatting.join('-')
        d_time = ('0'+d_datetime.toLocaleTimeString()).slice(-8,-3)
      }

      let e_a_time = null
      let e_d_time = null
      let e_a_date = null
      let e_d_date = null
      
      if(train.expected_arrival){ //not UTC!
        e_a_date = train.expected_arrival.toString().split('T')[0]
        e_a_time = train.expected_arrival.toString().split('T')[1].slice(0,5)
      }
      if(train.expected_departure){
        e_d_date = train.expected_departure.toString().split('T')[0]
        e_d_time = train.expected_departure.toString().split('T')[1].slice(0,5)
      }
      let train_check_ups = {}
      
      for(let i in train.check_ups){
        let checkup = train.check_ups[i]
        let date = null
        let time = null
        if(checkup.datetime_end){
          let datetime = new Date(checkup.datetime_end)
          let formatting = datetime.toLocaleDateString().split('/').reverse()
          formatting[2] = ('0'+ formatting[2]).slice(-2,3)
          formatting[1] = ('0'+ formatting[1]).slice(-2,3)
          date = formatting.join('-')
          time = ('0'+datetime.toLocaleTimeString()).slice(-8,-3)
        }
        let datetime_end = {date:date,time:time}
        train_check_ups[checkup.id] = {check_up_type:checkup.check_up_type.toString(),order_num:checkup.order_num,done:checkup.done,datetime_end:datetime_end}
      }
      let train_tags = {}
      for(let i in train.tags){
        let tag = train.tags[i]
        train_tags[tag.id.toString()] = {tag_type:tag.tag_type.toString(),done:tag.done}
      }
      for(let i in train.track_record){
        let date = null
        let time = null
        if(train.track_record[i].time){
          let datetime = new Date(train.track_record[i].time)
          let formatting = datetime.toLocaleDateString().split('/').reverse()
          formatting[2] = ('0'+ formatting[2]).slice(-2,3)
          formatting[1] = ('0'+ formatting[1]).slice(-2,3)
          date = formatting.join('-')
          time = ('0'+datetime.toLocaleTimeString()).slice(-8,-3)
        }
        train.track_record[i]['datetime'] = {date:date,time:time}
      }
      state_trains[train.id] = {kind:train.kind,'check_ups': train_check_ups,tags:train_tags,unit:train.unit,incoming:train.incoming,expected_tasks:train.expected_tasks,arrival:{'date':a_date,'time':a_time},'departure':{'date':d_date,'time':d_time},expected_arrival:{'date':e_a_date,'time':e_a_time},'expected_departure':{'date':e_d_date,'time':e_d_time},'track_record':train.track_record}
    }
    var state_tag_types = {}
    for(let i in tag_types){
      let tag_type = tag_types[i]
      state_tag_types[tag_type.id.toString()] = {name:tag_type.name}
    }
    var state_train_kinds = {}
    for(let i in train_kinds){
      let kind = train_kinds[i]
      let t_k_ch_ups = {}
      for(let i in kind.check_ups){
        let checkup = kind.check_ups[i]
        t_k_ch_ups[checkup.id] = {name:checkup.name}
      }
      state_train_kinds[kind.id] = {name:kind.name,img:kind.train_img,length:kind.length,check_ups:t_k_ch_ups}
    }
    commit('SET_DATA',{trains:state_trains, tag_types:state_tag_types, train_kinds:state_train_kinds, relevant_trains:relevant_trains})
  }
}

 