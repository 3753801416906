import Vue from 'vue'
import Vuex from 'vuex'
import * as trains from './modules/trains.js'
import * as tracks from './modules/tracks.js'
import * as notes from './modules/notes.js'
import * as me from './modules/me.js'
import { getField, updateField } from 'vuex-map-fields';
import { client, setHeaders } from "@/api/client";

Vue.use(Vuex)

const store = new Vuex.Store({
  modules:{
    trains,
    tracks,
    notes,
    me,
  },
  state: {
    datetime: 'undefined',
    loaded: false,
    zoom_interval:10000,
    zoom_ins:{},
    new_zoom:{},
    clickedZoom:[],
    map_img: undefined,
    loading :false,
    last_update: null,
    updating_exp : false,
    workshop_id:'',
  },
  mutations: {
    SET_ZOOMS(state,{interval,zoom_ins}){
      state.zoom_interval = interval
      state.zoom_ins = zoom_ins
    },
    SET_MAP_IMG(state,{map_img}){
      state.map_img = map_img
    },
    SET_INTERVAL(state,interval){
      state.zoom_interval = interval
    },
    SET_WORKSHOP(state,workshop_id){
      state.workshop_id = workshop_id
    },
    SET_LAST_UPDATE(state,{last_update}){
      state.updating_exp = false
      state.last_update = last_update
    },
    ADD_ZOOM(state,{zoom}){
      let id = zoom.id
      state.zoom_ins = {
        ...state.zoom_ins,
        [id] : {x:zoom.x,y:zoom.y,name:zoom.name, factor:zoom.factor, active:zoom.active}
    }
    },
    DEL_ZOOM(state,payload){
        delete state.zoom_ins[payload]
    },
    updateField
  },
  actions: {
    addZoom({state,commit}){
      return new Promise((resolve,reject)=> {
          let zoom = state.new_zoom
          zoom['workshop'] = me.state.userData.workshop.toString()
          client.post('api/zooms/',zoom).then((response)=>{
              commit('ADD_ZOOM',{zoom:response.data})
              resolve()
          }).catch(err => reject(err))})
    },
    deleteZoom({commit},id){
      return new Promise((resolve,reject)=> {
          client.delete('api/zooms/'+id+'/').then(()=>{
              commit('DEL_ZOOM', id)
              resolve()
          }).catch(err => reject(err))})
    },
    updateZoom({commit,state},id){
      return new Promise((resolve,reject)=> {
        client.patch('api/zooms/'+id+'/', state.zoom_ins[id] ).then((response)=>{
            commit('ADD_ZOOM',{zoom:response.data}) //funciona per actualitzar tbe
            resolve()
        }).catch(err => reject(err))})
    },
    updInterval({commit}, payload){
      let upd_wor = {'zoom_interval':payload}
      return new Promise((resolve,reject)=> {
        client.patch('api/workshops/'+me.state.userData.workshop.toString()+'/', upd_wor).then((response)=>{
            commit('SET_INTERVAL',{interval:response.data.zoom_interval*1000}) //funciona per actualitzar tbe
            resolve()
        }).catch(err => reject(err))})
    },
    refresh_all_data({dispatch,state}){
      state.loaded=false;
      return dispatch('set_all_data')
    },
    set_all_data({dispatch,commit,state}){
      return new Promise((resolve,)=> {
        if(state.loaded)
          resolve()
        else{
          let workshop_id = me.state.userData.workshop.toString()
          commit('SET_WORKSHOP',workshop_id)
          client
            .get('/api/workshops/'+workshop_id+'/')
            .then(response => {
              let interval = response.data.zoom_interval*1000
              let zoom_ins = {}
              for(let i in response.data.zoomIns){
                let zoom = response.data.zoomIns[i]
                zoom_ins[zoom.id] = {x:zoom.x,y:zoom.y, name:zoom.name,factor:zoom.factor, active: zoom.active}
              }
              commit('SET_ZOOMS',{interval:interval,zoom_ins:zoom_ins})
              commit('SET_MAP_IMG',{map_img:response.data.map_img})
              commit('SET_LAST_UPDATE', {last_update:new Date(response.data.last_update)})
              let p1 = dispatch('trains/setData', {trains:response.data.trains, tag_types:response.data.tag_types, train_kinds:response.data.train_kinds, relevant_trains: response.data.relevant_trains} )
              let p2 = dispatch('tracks/setData', {tracks:response.data.tracks,trains:response.data.trains, })
              let is_pizarra = false
              if(me.state.userData.group == 'Pizarra'){
                is_pizarra = true
              }
              let p3 = dispatch('notes/setData', {notes:response.data.notes, is_pizarra:is_pizarra})
              Promise.all([p1,p2,p3]).then(() =>{
                state.loaded=true
                resolve()
              })
              
            })
          }
      })
    },
    fill_expected_data(){
      return new Promise((resolve,) => {
        client
            .patch('/api/fill_expected_data/'+me.state.userData.workshop.toString()+'/').then(() => {
              resolve()
            })
      })
    }
  },
  getters: {
    getField
  
  },
 
})

if(localStorage.getItem('t')){
  let headers = {'Authorization':'JWT ' + localStorage.getItem('t')}
  setHeaders(headers)
  store.dispatch('me/getUserData')
}

export default store;


