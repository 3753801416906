<template>
    <v-menu
        :ref="content"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
        v-model="menu"
    >
        <template v-slot:activator="{ on }">
            <v-text-field
                autocomplete="off"
                :value="formatedDate"
                @input="handleInputTextField"
                :label="label"
                persistent-hint
                v-on="on"
                :readonly="readonly"
                v-on:keyup.enter="$emit('entered')"
                :background-color="bckColor"
                :hide-details="hide_details"
            ></v-text-field>
        </template>
        <v-date-picker
            :disabled="readonly"
            v-model="content"
            no-title
            color="primary"
            locale="es"
            @change="handleNewDate"
        ></v-date-picker>
    </v-menu>
</template>

<script>
    export default {
        name: "DatePicker",
        props:{
            value: String,
            label:String,
            readonly: {
                type: Boolean,
                required: false,
                default: false
            },
            hide_details:{
                type: Boolean,
                required: false,
                default: false,
            }
        },
        data() {
            return {
                menu: false,
                content: this.value,
                bckColor: "#ffffff", // error: #ff9898
            }
        },
        methods: {
            handleNewDate (e) {
                this.$emit('input', e);
                this.menu = false;
            },
            handleInputTextField(e) {
                let l = e.split('/');
                if (e.length==0){
                    this.handleNewDate(e);
                    this.bckColor = "#ffffff";
                    return
                }
                if (l.length==3 && l[2]!=='' && l[2].length==4 && l[1]!=='' && l[0]!=='') {
                    this.content = l[2]+'-'+l[1]+'-'+l[0];
                    if (Date.parse(this.content)) {
                        this.handleNewDate(this.content);
                        this.bckColor = "#ffffff";
                    } else { 
                        this.bckColor = "#ff9898";
                    }
                } else {
                    this.bckColor = "#ff9898";
                }
            },
        },
        computed:{
            formatedDate(){
                if (this.value && this.value !== '') {
                    let l = this.value.split("-");
                    return l[2]+'/'+l[1]+'/'+l[0];
                } else {
                    return "";
                }
            },
        },
    }
</script>

<style scoped>

</style>