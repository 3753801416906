<template>
   <v-app id="app">
     <v-app-bar app clipped-left clipped-right style="position:relative;" class="flex-grow-0" >
          <v-row>
            <v-col cols="2">
              <v-img
                      :src="require('./assets/irvia.png')"
                      contain
                      max-width="175"
              /> 
            </v-col>
            <v-col cols="3">
            </v-col>
            <v-col cols="2">
              <div v-if="userData() && ['Administrador'].includes(userData()['group'])" style="position:relative;top:7px">
                <v-row>
                  <v-btn
                          v-show="userData()"
                          text
                          v-on:click="downloadForm"
                          style="background-color:rgb(211,202,216);"
                          :loading="downloading"
                  >
                      Descargar Informe 
                  </v-btn>
                </v-row>
            </div>
            </v-col>
              
            
            <v-col cols="2">
            <div v-if="userData() && ['Administrador'].includes(userData()['group'])" style="position:relative;top:7px">
                <v-row>
                  <v-btn
                          v-show="userData()"
                          text
                          v-on:click="updatePlan"
                          style="background-color:rgb(211,202,216);"
                          :loading="updating_exp"
                  >
                      Actualizar Planificación
                  </v-btn>
                </v-row>
                <v-row style="color:darkred;">
                  {{updateInfo}}
                </v-row>
            </div>
            </v-col>
            <v-col cols="2">
            <div v-if="userData() && ['Administrador'].includes(userData()['group'])" style="position:absolute;top:2px">
              <v-btn
                      v-show="userData()"
                      text
                      v-on:click="goToEditor"
                      style="background-color:rgb(211,202,216);"
              >
                  Ir al {{goTo}}
              </v-btn>
            </div>
            </v-col>
            <v-col cols="1">
              <div v-if="userData()" style="position:absolute;right:1%">
                <b>{{userData().username}}</b>
                <v-btn
                        v-show="userData()"
                        text
                        v-on:click="logOut"
                        height="auto"
                >
                    <v-icon color="black">mdi-logout</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
      </v-app-bar>
        
      <div style="position:relative;"> 
        <router-view/>
      </div>
    
  </v-app>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapActions, mapGetters } from 'vuex';
import { client } from "@/api/client"
export default {
  name: 'App',
  data: () => ({
    interval: 'undefined',
    goTo: 'dashboard',
    downloading: false,
  }),
  methods:{
    logOut(){

    },
    updateTime(){
      this.datetime = new Date()
    },
    async updatePlan(){
      this.updating_exp = true
      await this.fill_expected_data()
      this.refresh_all_data()
    },
    downloadForm(){
      this.downloading = true
      client.get('api/get_csv_form/'+this.userData().workshop.toString()+'/',{ responseType: "blob" }).then((res)=>{
        const blob = new Blob([res.data], { type: "csv/text" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = this.datetime.toLocaleDateString().replace('/','_')+'informe.csv';
        link.click();
        URL.revokeObjectURL(link.href);
        this.downloading = false
      }).catch(()=>{
        this.downloading = false
      })
    },
    goToEditor(){
      var params = {}
      params.params = {} 
      if(window.location.pathname == '/editor'){
        params.name = "Dashboard"
        this.goTo = "editor"
      }
      else{
        params.name = "trackEditor" 
        this.goTo = "dashboard"
      }
      this.$router.push(params);
    },

    ...mapActions(['set_all_data','refresh_all_data','fill_expected_data']),
    ...mapActions('me',['logOut'])
  },
  computed:{
    ...mapFields(['datetime','last_update','loading','updating_exp']),
    ...mapGetters('me',['userData']),
    updateInfo(){
      let timeago = this.datetime-this.last_update
      if(timeago < 31556952000){
        let mins_left = parseInt(timeago/60000)
        return 'Última actualización hace '+ mins_left.toString() +'min.'
      }
      else{
        return 'Cargando...'
      }
    },
  },
  created(){
    this.interval = setInterval(this.updateTime, 1000);
    if(window.location.pathname != '/editor'){
        this.goTo = 'editor'
      }
      else{
        this.goTo = 'dashboard'
      }
  },

};
</script>


