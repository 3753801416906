<template>
    <div> 
        <div class="sidebar" :style="{width:sidebarWidth}">
            <span
                class="collapse-icon"
                :class="{'rotate-180': !collapsed}"
                @click="toggleSidebar">
                <font-awesome-icon icon="fa-solid fa-angles-right fa-10x" />
            </span>
            <v-select 
                v-if="!collapsed"
                class='selector'
                :items="filters" 
                label= "Filtrar por serie" 
                v-model="train_type"
                item-text="name"
                item-value="value"
                :hide-details="true"
                return-value>
                </v-select>
            <div class="drop-space" :style="{backgroundColor:dropColor}"
                @dragenter.prevent = "dragEnter"
                @dragleave.prevent = "dragLeave"
                @dragover.prevent 
                @drop.prevent="drop">
                <slot class="train-slot">
                    <div v-for="(e,j) in getTracklessTrains" :key="j"  >
                        <Train 
                        style="padding-top:0.5vh;padding-bottom:0.5vh;"
                        v-if="trains[e].kind == train_type || train_type == 'all' || (train_type=='today' && relevant_trains.includes(e))"
                        :id="e" 
                        :trackHeight="dotHeight" 
                        >
                        </Train>
                    </div>
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
import  Train  from './Train.vue'
import { mapFields } from 'vuex-map-fields';
import { mapActions, mapGetters } from 'vuex';
export default {
    data(){
        return{
            sidebarWidth:'0',
            collapsed : true,
            dropColor: 'rgb(159, 100, 173 )',
            train_type:'all',
        }
    },
    components:{
        Train,
    },
    computed:{
        dotHeight(){
            if(this.collapsed){
                return '0px'
            }
            else{
                return '100px'
            }
        },
        filters(){
            let filters = []
            for(let i in Object.keys(this.trainKinds)){
                let key = Object.keys(this.trainKinds)[i]
                let name = this.trainKinds[key].name
                filters.push({value:key,name:name})
            }
            filters.push({value:'today',name:'Planificados 24h'})
            filters.push({value:'all',name:'Todos'})
            return filters
        },
        ...mapGetters('tracks',['getTracklessTrains']),
        ...mapFields('trains',['dragging','tracklessTrains','trains','trainKinds','relevant_trains']),
    },
    methods:{
        toggleSidebar(){
            if(this.collapsed){
                this.sidebarWidth = '180px'
            }
            else{
                this.sidebarWidth = '0'
            }
            this.collapsed = !this.collapsed
        },
        dragEnter(){
            this.dropColor = 'rgb(213, 162, 224)'
        },
        dragLeave(){
            this.dropColor = 'rgb(159, 100, 173)'
        },
        async drop(){
            if(this.dragging.length == 1){
                // this.tracklessTrains.push(this.dragging[0].train)
                let origin = this.dragging[0].track
                let train_id = this.dragging[0].train
                if(origin != 'sidebar'){
                    this.addTracklessTrain(train_id)
                    await this.deepDeleteTrain({train:train_id, track:origin });
                    this.get_train_data(train_id)
                }
                this.dropColor = 'rgb(159, 100, 173)'
            }
        },
        ...mapActions('tracks',['deepDeleteTrain','addTracklessTrain','deleteTracklessTrain']),
        ...mapActions('trains',['get_train_data']),
    }
    
}
</script>

<style scoped>
.sidebar{
    background-color: rgb(211, 194, 210  );
    float:left;
    position:fixed;
    z-index:5;
    top:65px;
    left:0;
    bottom:0;

    transition: 0.3s ease;

    display: flex;
    flex-direction: column;
}
.collapse-icon {
    position:sticky;
    top:30px;
    margin-left:0;
    right: 10px;
    transition: 0.2s linear;
    color:black;
}
.rotate-180{
    transform:rotate(180deg);
    transition:0.2s linear;
}
.drop-space{
    position:relative;  
    display: flex;
    flex-direction: column;
    top: 10px;
    border-radius: 0.3vw;
    width: 100%;
    height:100%;
    overflow: auto;
}
.train-slot{
    position:relative;
    width:150px;
}
.selector{
    position:relative;
    max-height:7%;
    height:7%;
}
</style>