<template>
    <div v-on="tooltip"
        :id="id"
        :class="{'incoming' : trains[id].incoming, 'trackMode':!isInSidebar,'sidebarMode':isInSidebar}"
        draggable
        @dragstart="dragStart"
        @dragend="dragEnd"
        @dblclick.stop
    >   
        <div v-if="!isInSidebar" style="position:relative;display:flex;height:40%;margin: 0 -2px">
            <div ref="checkups" v-for="(check, i) in trainTopShow" :key="i" :class="{'checkups' : !check.done, 'checkups_done':check.done}"
             :style="{opacity: opacity,fontSize: (parseInt(trackHeight)*0.3).toString() + 'px'}">
                {{check.name}}
            </div>
        </div>
        <div class="train" :class="{'sidebarMode':isInSidebar} " @click="trainClick">
            <div class="dot">
                <div :style="{textAlign: 'center',lineHeight: dotHeight,verticalAlign: 'middle', height: dotHeight, width: dotHeight,
                fontSize: (parseInt(trackHeight)*0.3).toString() + 'px',color:'black'}">
                    <b>{{trains[id].unit}}</b>
                </div>
            </div>
            <img 
                v-if="getTrainImg(id)"
                :src="getTrainImg(id)"
                :style="imgStyle"
                class="center"
                @click="trainClick"
            />
        </div>
        
        <div v-if="!isInSidebar" style="position:relative;display:flex;height:50%;" >
            <div :style="{opacity: opacity, position:'relative', width:'auto',height:'100%',
                backgroundColor:timeColor,fontSize: (parseInt(trackHeight)*0.35).toString() + 'px', top: '0',zIndex:'5'}"
                class="center">
                {{showTime}}
            </div>
        </div>
            
    </div>
</template>

<script>

import {mapActions,mapGetters} from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
    data(){
        return{
            opacity: 1 ,
            imgDir: "",
        }
    },
    props: ['id','trackHeight','tooltip'],
    name: 'Train',
    methods:{
        trainClick(){
            if(this.isClicked){
                let i = this.clickedTrain.indexOf(this.id)
                this.clickedTrain.splice(i,1)
            }
            else{
                this.clickedTrain.push(this.id)
            }
        },
        dragStart(){
            let originTrack = this.getTrainTrack(this.id);
            this.dragTrain({train:this.id, track:originTrack});
            setTimeout(() => {
                this.opacity = 0.3;
            }, 0);
        },
        dragEnd(){
                this.clearDrag();
                this.opacity = 1;
            
        },
        ...mapActions('tracks',['deleteTrain','dragTrain','addTrain','clearDrag']) ,
        ...mapActions('trains',['dragTrain','clearDrag']) ,
    },
    computed:{
        arrivalDatetime(){
            if(this.trains[this.id].arrival.date && this.trains[this.id].arrival.time){
                return new Date(this.trains[this.id].arrival.date + 'T' + this.trains[this.id].arrival.time + ':00')
            }
            else if(this.trains[this.id].expected_arrival.date && this.trains[this.id].expected_arrival.time){
                return new Date(this.trains[this.id].expected_arrival.date + 'T' + this.trains[this.id].expected_arrival.time + ':00')
            }
            else{
                return ''
            }
        },
        departureDatetime(){
            if(this.trains[this.id].departure.date && this.trains[this.id].departure.time){
                return new Date(this.trains[this.id].departure.date + 'T' + this.trains[this.id].departure.time + ':00')
            }
            else if(this.trains[this.id].expected_departure.date && this.trains[this.id].expected_departure.time){
                return new Date(this.trains[this.id].expected_departure.date + 'T' + this.trains[this.id].expected_departure.time + ':00')
            }
            else{
                return ''
            }

        },
        timeLeft(){
            if(this.arrivalDatetime && this.arrivalDatetime != 'Invalid Date' && this.isIncoming){
                let timeleft = this.arrivalDatetime-this.datetime
                return timeleft
            }
            else if(this.departureDatetime && this.departureDatetime != 'Invalid Date'){
                let timeleft = this.departureDatetime-this.datetime
                return timeleft
            }
            return 3600001
        },
        timeColor(){
            if(this.timeLeft < 0){
                return 'rgb(255,80,51)'
            }
            else if(this.timeLeft < 1800000){
                return 'rgb(255,150,50)'
            }
            else if(this.timeLeft < 3600000){
                return 'yellow'
            }
            else{
                return 'white'
            }
        },
        isIncoming(){
            if(this.arrivalDatetime && this.arrivalDatetime != 'Invalid Date'){
                let timeleft = this.arrivalDatetime-this.datetime
                if(Math.abs(timeleft) < 86400000 && this.trains[this.id].incoming){ //24h a milisegons
                    return true
                }
                else{
                    return false
                }
            }
            else{
                return false
            }
        },
        isLeaving(){
            if(this.departureDatetime && this.departureDatetime != 'Invalid Date' && !this.trains[this.id].incoming){
                let timeleft = this.departureDatetime-this.datetime
                if(Math.abs(timeleft) < 86400000){ //24h a milisegons
                    return true
                }
                else{
                    return false
                }
            }
            else{
                return false
            }
        },
        showTime(){
            if(this.arrivalDatetime && this.arrivalDatetime != 'Invalid Date' && this.isIncoming){
                return ('0'+this.arrivalDatetime.getHours().toString()).slice(-2) + ':' + ('0'+this.arrivalDatetime.getMinutes().toString()).slice(-2)
                
            }
            else if(this.departureDatetime && this.departureDatetime != 'Invalid Date' && this.isLeaving){
                return ('0'+this.departureDatetime.getHours().toString()).slice(-2) + ':' + ('0'+this.departureDatetime.getMinutes().toString()).slice(-2)    
            }
            else{
                return ''
            }
        },
        isInSidebar(){
            if(this.getTracklessTrains.includes(this.id)){
                return true
            }
            else{
                return false
            }
        },
        imgStyle(){
            if (this.isInSidebar){
                return {height:'auto', width:'100%',opacity: this.opacity}
            }
            else{
                return {height:'100%', width:'auto',opacity: this.opacity}
            }
        },
        trainTopShow(){
            let train = this.trains[this.id]
            let potential_checkups = Object.keys(this.trainKinds[train.kind].check_ups)
            let potential_tags = Object.keys(this.tag_types)
            let train_top_show = []
            for(let j in Object.keys(train.check_ups)){
                let key = Object.keys(train.check_ups)[j]
                let checkup = train.check_ups[key]
                if(potential_checkups.includes(checkup.check_up_type)){
                    train_top_show.push({name:this.trainKinds[train.kind].check_ups[checkup.check_up_type].name, done:checkup.done})
                }
            }
            for(let j in Object.keys(train.tags)){
                let key = Object.keys(train.tags)[j]
                let tag = train.tags[key]
                if(potential_tags.includes(tag.tag_type)){
                    train_top_show.push({name:this.tag_types[tag.tag_type].name, done:tag.done})
                }
            }
               
            return train_top_show
        },
        dotHeight(){
            if(this.isInSidebar){
                return (parseInt(this.trackHeight)*0.4).toString() + 'px'
            }
            else{
                return (parseInt(this.trackHeight)*0.6).toString() + 'px'
            }
        },
        ...mapGetters('tracks',['getTrainTrack','getTracklessTrains']),
        ...mapGetters('trains',['getTrainImg', 'getTrainInfo']),
        ...mapGetters('me',['userData']),
        ...mapFields('trains',['trains','dragging','clickedTrain','items','trainKinds','tag_types']),
        ...mapFields(['datetime']),

    },
    
}



    
</script>

<style scoped lang="scss">
.trackMode  {
  height:100%;
  width:auto; 
  position:relative;
  &.incoming{
    top: -20%;
    transform: rotate(-15deg); 
    }
}
.sidebarMode {
    height:auto;
    width:100%; 
    position:relative;
}
.checkups{
    position: relative;
    width:auto;
    height:100%;
    top:0;
    background-color: lightgray;
    margin: 0 2px;
    border-radius: 10%;
    border:1.5px solid black;
    vertical-align: 'middle';
    line-height: 110%;
}
.checkups_done{
    border-radius: 10%;
    position: relative;
    width:auto;
    height:100%;
    top:0;
    background-color: lightgreen;
    margin: 0 2px;
    border:1.5px solid black;
    vertical-align: 'middle';
    line-height: 110%;
}


.train{
    position:relative;
    height:60%;
    top: 0;
    &.sidebarMode{
        width:100%;
        height: auto;
    }
}
.dot {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: orange;
  border-radius: 50%;
  display: inline-block;
  position:absolute;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-style: bald;
}
.center{
    display:block;
    margin:auto;
}
</style>