<template>
    <div>
    <v-text-field 
        :background-color="computedBck"
        @keypress="isNumber($event,1)"
        @input="handleInput($event)"
        placeholder="hh:mm" 
        :label="label"
        :value="computedValue"
        :hide-details="true"></v-text-field>
        </div>
</template>


<script>
export default {
    name:'TimeWidget',
    data() {
        return{
            text:'',
        }
    },
    props:['label','value'],
    computed:{
        computedBck(){
            if(this.valid()){
                return 'transparent'
            }
            else{
                return '#ff9898'
            }
        },
        computedValue(){
            if (this.value && this.text=='') {
                return this.value
            } else {
                return this.text;
            }
        },
    },
    methods:{
        valid(){
            if(this.text.length == 0){
                return true
            }
            if(this.text.length == 5 && this.text[2] == ':'){
                let h = this.text.slice(0,2)
                let m = this.text.slice(3,5)
                if(this.isNumber(h,0) && parseInt(h) < 24 && this.isNumber(m) && parseInt(m) < 60){
                    return true
                }
            }
            return false

        },
        isNumber: function(evt,plus) {
            evt = (evt) ? evt : window.event
            var charCode = (evt.which) ? evt.which : evt.keyCode
            if ((charCode > 31 && (charCode < 48 || charCode > 58-plus))) {
                evt.preventDefault()
            } else {
                return true
            }
        },
        handleInput(e){
            this.text = e
            if(e.length == 2){
                this.text += ':'
            }
            if(this.valid()){
                this.$emit('input', this.text)
            }
            else{
                this.$emit('input', '')
            }
        },
    },
}
</script>