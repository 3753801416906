<template>
    <div class = "nota"
        :style="{opacity: opacity, zIndex: zind, backgroundColor: bckColor, color: txtColor, fontSize: (parseInt(imgHeight)*0.02).toString() + 'px'}"
        draggable
        @dragstart="dragStart"
        @dragend="dragEnd"
        @dblclick.stop
        @click="clicked"
        >
        <!-- <div class="modal-overlay"></div> -->
        
         {{notes[id]['text']}}
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
    data(){
        return{
            opacity:1,
            zind: 3,
        }
    },
    props: ['id', 'bckColor','txtColor','imgHeight'],
    computed:{
        ...mapFields('notes',['notes','clickedNote']),
        ...mapGetters('me',['userData']),
    },
    methods:{
        dragStart(event){
            let clickCoor = [event.layerX, event.layerY]
            this.dragNote({note:this.id, coor:clickCoor});
            setTimeout(() => {
                this.opacity = 0.3;
                this.zind = 2
            }, 0);
        },
        dragEnd(){
            this.opacity = 1;
            this.zind = 3
            this.clearDragNotes();
            
        },
        clicked(){
            this.clickedNote.push({type:'edit',id:this.id})
        },
        ...mapActions('notes',['dragNote', 'clearDragNotes'])
    }
    
}
</script>

<style scoped>
.nota{
    font-size: 1vw;
}

</style>
