<template>
  <div>
    <DashboardEditor ></DashboardEditor>
  </div>
</template>

<script>
import DashboardEditor from '../components/DashboardEditor.vue'

  export default {
  components: { DashboardEditor },
  name: 'trackEditor',
  
  }
</script>