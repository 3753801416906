import axios from "axios";

// const CSRF_COOKIE_NAME = "csrftoken";
// const CSRF_HEADER_NAME = "X-CSRFToken";

const baseURL = process.env.VUE_APP_API_ENDPOINT

let client;

function setHeaders(headers){
  client = axios.create({
    baseURL, headers,
    // xsrfCookieName: CSRF_COOKIE_NAME,
    // xsrfHeaderName: CSRF_HEADER_NAME
  });
}

export {client, setHeaders};