<template>
    <v-container class="modal" >
            <v-row>
                <v-col cols='6'>
                <h1>{{track_name}} </h1><br>
                <p>id:{{id}}</p> <br>
                </v-col>
                <v-col cols='5'>
                <v-btn  style="backgroundColor:red" @click="del" >Eliminar via</v-btn>
                </v-col>
            </v-row>
            <br>
            <!-- <v-row>
                <v-col cols='2'>
                    Tipo:
                </v-col>
                 <v-col cols='10'>
                    <v-autocomplete  v-model="type" :items="trackTypes" label="Elija el tipo de via"> </v-autocomplete>
                </v-col>           
            </v-row> -->
            <v-row>
                <v-text-field  
                v-model="track_name" 
                label="Nombre"
                placeholder="Nombre via"
                :hide-details="true"
                ></v-text-field>
            </v-row>
            <v-row>
                <v-text-field  
                v-model="length" 
                label="Longitud"
                placeholder="Longitud via (en metros)"
                :hide-details="true"
                ></v-text-field>
            </v-row>
            <v-row>
                <v-checkbox
                    v-model="has_tension"
                    label="Puede tener tensión"
                    >
                </v-checkbox>
            </v-row>
            <v-row>
                <v-col cols='8'>
                    <v-btn @click="cancel">Cancelar</v-btn>
                </v-col>
                
                 <v-col cols='4' >
                    <v-btn @click="save" style="backgroundColor:green">Guardar</v-btn>
                </v-col>
            </v-row>
    </v-container >
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';
export default {
    name:'trackPopUp',
    data(){
        return{
            type: '',
            id:'',
            track_name:'',
            length: '0',
            has_tension: false,

        }
    },
    computed:{
        ...mapFields('tracks',['trackInfo','clickedTrack','trackTypes','trackTension']),
    },
    methods:{
        cancel(){
            this.clickedTrack = []
        },
        save(){
            let length = parseFloat(this.length.replace(',','.'))
            if(this.id == 'new_track'){
                this.addTrack({name:this.track_name,length:length,has_tension:this.has_tension}).then(()=>{
                    this.clickedTrack = []
                    this.$emit('updatetrack')
                })
            }
            else{
                this.editTrack({id:this.id,name:this.track_name,length:length,has_tension:this.has_tension}).then(()=>{
                    this.clickedTrack = []
                    this.$emit('updatetrack')
                })
            }
            
            
        },
        del(){
            this.deleteTrack(this.id).then(()=>{
                this.clickedTrack= []
                this.$emit('updatetrack')})
        },
        ...mapActions('tracks',['deleteTrack','addTrack','editTrack'])
    },
    mounted(){
        this.id = this.clickedTrack[0]
        if(this.id != 'new_track'){
            this.track_name = this.trackInfo[this.id].name
            this.has_tension = this.trackInfo[this.id].has_tension
            if(this.trackInfo[this.id].length){
                this.length = this.trackInfo[this.id].length.toString()
            }
            else{
                this.length = '0'
            }
        }
        //this.type = this.trackInfo[this.id].type
    }
}
</script>

<style scoped>

.modal {
 position: fixed;
 z-index: 10;
 top: 40%;
 left: 40%;
 width: 100%;
 max-width: 400px;
 background-color: #FFF;
 border-radius: 16px;
 
 padding: 25px;
}
</style>
