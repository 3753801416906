import { getField, updateField } from 'vuex-map-fields';
import { client } from "@/api/client";
export const namespaced = true

export const state = {
    trackPos:{},

    trackContent:{},

    tracklessTrains:[],

    trackInfo:{},

    trackTension:{},

    tracksCreated: 0,

    clickedTrack:[],

    newTrack:{},

    trackTypes:['lavado','taller','parking'],

    moving:{}
}

export const getters = {

    getTrainTrack: (state) => (train) => {
        for(let i in Object.keys(state.trackContent)){
            let key = Object.keys(state.trackContent)[i]
            for(let j in state.trackContent[key]){
                if(state.trackContent[key][j] == train){
                    return key
                }
            }
        }
        if(state.tracklessTrains.includes(train)){
            return 'sidebar'
        }
        else{
            return ''
        }

    },
    getTracklessTrains: (state) => {
        return state.tracklessTrains
    },
    getField,
}

export const mutations = {
    MOVE_TRAIN(state,payload){
        for(let i in Object.keys(state.trackContent)){
            let key = Object.keys(state.trackContent)[i]
            const index = state.trackContent[key].indexOf(payload.train)
            if(index > -1){
                state.trackContent[key].splice(index,1)
                state.trackContent[payload.dest].push(payload.train)
                break
            }
        }
    },
    DELETE_TRAIN(state,payload){
        const index = state.trackContent[payload.track].indexOf(payload.train)
        state.trackContent[payload.track].splice(index,1)
    },
    ADD_TRAIN(state,payload){
        state.trackContent[payload.track].splice(payload.index,0,payload.train)
    },
    NEW_TRACK(state,{position}){
        state.newTrack['position'] = position
    },
    ADD_TRACK(state,{track}){
        let id = track.id
        state.trackPos[id] = track.pos
        state.trackContent[id] = []
        state.trackInfo[id] = {'name':track.name,'length':track.length, 'type': null, 'has_tension':track.has_tension}
        state.trackTension[id] = false
    },
    EDIT_TRACK(state,{id,name,length,has_tension}){
        state.trackInfo[id].name = name
        state.trackInfo[id].length = length
        state.trackInfo[id].has_tension = has_tension
    },
    DEL_TRACK(state,payload){
        delete state.trackPos[payload]
        delete state.trackContent[payload]
        delete state.trackInfo[payload]
    },
    WARN_TRACK(state,payload){
        state.trackTension[payload] = !state.trackTension[payload]
    },
    SET_WARN_TRACK(state,payload){
        state.trackTension[payload.id] = payload.tension
    },
    SET_DATA(state,data){
        for(let i in Object.keys(data)){
            let key = Object.keys(data)[i]
            state[key] = data[key]
        }
      },
    SET_TRACK_CONTENT(state,payload){
        state.trackContent[payload.track] = payload.content
    },
    DELETE_TRACKLESSTRAIN(state,id){
        let temp=[]
        for(let i in state.tracklessTrains){
            if(state.tracklessTrains[i]!=parseInt(id)){
                temp.push(state.tracklessTrains[i])
            }
        }
        state.tracklessTrains=temp
    },
    ADD_TRACKLESSTRAIN(state, id){
        state.tracklessTrains.push(parseInt(id))
    },
    updateField,
}
export const actions = {
    moveTrain({commit}, {train, dest}){
        commit('MOVE_TRAIN', {train, dest})
    },
    deleteTrain({commit}, {train, track}){
        commit('DELETE_TRAIN', {train, track})
    },
    deepDeleteTrain({commit}, {train, track}){
        return new Promise((resolve,reject)=> {
        commit('DELETE_TRAIN', {train, track})
        client.patch('api/move_train/'+train+'/', {track: null }).then((response)=>{
            commit('SET_TRACK_CONTENT',{track:track,content:response.data['ordered_trains']})
            resolve()
            
        }).catch(err => reject(err))})
    },
    addTrain({commit}, {train, track, i}){
        return new Promise((resolve,reject)=> {
        const index = i || 100
        commit('ADD_TRAIN', {train, track, index})
        client.patch('api/move_train/'+train+'/', {track: track }).then((response)=>{
            commit('SET_TRACK_CONTENT',{track:track,content:response.data['ordered_trains']})
            resolve()
                
        }).catch(err => {
            commit('ADD_TRAIN', {train:state.moving.train,track:state.moving.track,index})
            reject(err)})
    
    })
        
    },
    deleteTrack({commit},id){
        return new Promise((resolve,reject)=> {
            client.delete('api/tracks/'+id+'/').then(()=>{
                commit('DEL_TRACK', id)
                resolve()
            }).catch(err => reject(err))})
    },
    newTrack({commit},{position}){
        commit('NEW_TRACK', {position})
    },
    addTrack({commit},{name,has_tension,length}){
        return new Promise((resolve,reject)=> {
            let coords = state.newTrack.position
            let new_track = {x:coords.left,y:coords.top,height:coords.height,width:coords.width,length:length,has_tension:has_tension,tension:false,trains:[],workshop:'1',name:name}
            client.post('api/tracks/',new_track).then((response)=>{
                let track = {}
                track['pos'] = {top:response.data.y,left:response.data.x,height:response.data.height,width:response.data.width}
                track['id'] = response.data.id
                track['name'] = response.data.name
                track['length'] = response.data.length
                track['has_tension'] = response.data.has_tension
                commit('ADD_TRACK',{track:track})
                resolve()
            }).catch(err => reject(err))})
    },
    editTrack({commit},{name,id,has_tension,length}){
        return new Promise((resolve,reject)=> {
            client.patch('api/tracks/'+id+'/',{name:name,has_tension:has_tension,length:length}).then((response)=>{
                commit('EDIT_TRACK',{id:response.data.id.toString(),name:response.data.name,has_tension:response.data.has_tension,length:response.data.length})
                resolve()
            }).catch(err => reject(err))})
    },
    warnTrack({commit},id){
        commit('WARN_TRACK', id)
        client.patch('api/tracks/'+id+'/', {tension: state.trackTension[id] }).then((response=>{
            commit('SET_WARN_TRACK',{id:id,tension:response.data.tension})
        }))
    },
    setData({commit},{tracks,trains}){
        var data = {trackPos:{},trackContent:{},trackTension:{},tracksCreated:tracks.length,tracklessTrains:[],trackInfo:{}}
        for(let i in tracks){
            let track = tracks[i]
            data.trackPos[track.id] = {top:track.y,left:track.x,height:track.height,width:track.width}
            data.trackTension[track.id] = track.tension
            data.trackContent[track.id] = []
            data.trackInfo[track.id] = {name:track.name,length:track.length,has_tension:track.has_tension}
            for(let j in track.ordered_trains){
                let train = track.ordered_trains[j]
                data.trackContent[track.id].push(train)
            }
        }
        for(let i in trains){
            let train = trains[i]
            let trackless = true
            for(let j in tracks){
                let track = tracks[j]
                if(track.ordered_trains.includes(train.id)){
                    trackless = false
                }
            }
            if(trackless){
                data.tracklessTrains.push(train.id)
            }
        }
      commit('SET_DATA',data)
    },
    deleteTracklessTrain({commit},id){
        commit('DELETE_TRACKLESSTRAIN',id)
    },
    addTracklessTrain({commit},id){
        commit('ADD_TRACKLESSTRAIN',id)
    },    
}


