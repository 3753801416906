import { getField, updateField } from 'vuex-map-fields';
import { client } from "@/api/client";

export const namespaced = true

export const state = {
    notes : {},
    notesCreated : 0,
    new_note : {},
    draggNote : [],
    clickedNote : [],
}
export const getters = {
    getField
}
export const mutations = {
    DRAG_NOTE(state,payload){
        state.draggNote.push(payload)
    },
    CLEAR_DRAG_NOTES(state){
        state.draggNote = []
    },
    ADD_NOTE(state,payload){
        state.notes = {
            ...state.notes,
            [payload.id] : payload.note
        }
    },
    DEL_NOTE(state,payload){
        delete state.notes[payload]
    },
    SET_DATA(state,data){
        state.notes = data
      },
    updateField
}
export const actions = {
    dragNote({commit}, {note, coor}){
        commit('DRAG_NOTE', {note, coor})
    },
    clearDragNotes({commit}){
        commit('CLEAR_DRAG_NOTES')
    },
    addNewNote({commit}, {id,coor}){
        commit('ADD_NOTE', {id, coor})
    },
    deleteNote({commit},id){
        return new Promise((resolve,reject)=> {
            client.
                delete('/api/notes/'+id+'/').then(() => {
                    commit('DEL_NOTE', id)
                    resolve()
                }).catch(err => reject(err))
             })
        
    },
    setData({commit},{notes,is_pizarra}){
        let state_notes = {}
        for(let i in notes){
            let note = notes[i]
            if(!is_pizarra || note.visible){
                state_notes[note.id] = {name:note.name,text:note.text, coor:[note.x,note.y], bckColor:note.bckColor,txtColor:note.txtColor, visible: note.visible}
            }
        }
        commit('SET_DATA',state_notes)
    },
    updateNote(context,id){
        let stateNote = state.notes[id]
        let note = {}
        note['text'] = stateNote.text
        note['x'] = stateNote.coor[0]
        note['y'] = stateNote.coor[1]
        note['bckColor'] = stateNote.bckColor
        note['txtColor'] = stateNote.txtColor
        note['visible'] = stateNote.visible
        client.
            patch('api/notes/'+id+'/', note)
                
    },
    createNote({commit,rootState}){
        return new Promise((resolve,reject)=> {
            let stateNote = state.new_note
            let note = {}
            note['text'] = stateNote.text
            note['x'] = stateNote.coor[0]
            note['y'] = stateNote.coor[1]
            note['bckColor'] = stateNote.bckColor
            note['txtColor'] = stateNote.txtColor
            note['visible'] = stateNote.visible
            note['workshop'] = parseInt(rootState.workshop_id)
            client.
                post('api/notes/', note).then(response => {
                    let new_id = response.data.id 
                    let new_note = {text:note.text, coor:[note.x,note.y], bckColor:note.bckColor,txtColor:note.txtColor}
                    commit('ADD_NOTE', {id:new_id,note:new_note})
                    resolve()
                }).catch(err => reject(err))
             })
    }
}