<template>
  <div>
    <router-link to="/dashboard">Dashboard </router-link> ---------
    <router-link to="/editor"> Editor</router-link>
    <router-view/>
  </div>
</template>

<script>

  export default {
  name: 'Home',
  }
</script>
