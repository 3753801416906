<template>
    <v-main class="bg">
        <v-col
                align="center"
                justify="center"
        >
            
                <v-card width="400" class="mx-auto mt-12" color="rgba(255, 255, 255, 0.5)">
                    <v-spacer/>
                    <v-card-text>
                        <v-form>
                            <v-text-field
                                    label="Usuario"
                                    prepend-icon="mdi-account-circle"
                                    v-model="username"
                                    v-on:keyup.enter="LogIn"
                            />
                            <v-text-field
                                    :type="showPassword ? 'text' : 'password'"
                                    label="Contraseña"
                                    prepend-icon="mdi-lock"
                                    v-model="password"
                                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    @click:append="showPassword = !showPassword"
                                    v-on:keyup.enter="LogIn"
                            />
                            <p v-if="error" class="red--text">{{error}}</p>
                        </v-form>
                    </v-card-text>
                    <v-divider />
                    <v-card-actions>
                        <v-spacer />
                        <v-btn v-on:click="LogIn" color="darkgray">Login</v-btn>
                    </v-card-actions>
                </v-card>
           
        </v-col>
    </v-main>
</template>
<script>
import { mapActions } from 'vuex';

export default {
    name: "login",
    data() {
        return {
            showPassword: false,
            username: "",
            password: "",
            error: "",
        };
    },
    methods: {
        async LogIn() {
            try{
                await this.obtain_token({username:this.username, password:this.password})
                await this.getUserData()
                var params = { name: "Dashboard", params: {} };
                this.$router.push(params);
            }catch(e){
                this.error = e.toString()
            }
            

        },
        ...mapActions('me',['obtain_token','getUserData']),
        
    },
};
</script>
<style scoped>
    .bg {
        width: 100%;
        height: 100%;
        top:30%;
        position: absolute;
    }
</style>