<template>
    <div class="modal2" @dblclick.stop>
            <v-row>
                <v-col cols="3">
                    <div style="white-space: pre-line; overflow:auto">
                        <p style="font-size:2vh;">Serie:&nbsp;<b > {{trainKinds[trains[id].kind].name}} </b></p>
                        
                    </div>
                </v-col>
                <v-col cols="6">
                <div class="top_train">
                    <div class="dot">
                        <div :style="{textAlign: 'center',lineHeight: '50px',verticalAlign: 'middle', height: '50px', width: '50px'}">
                            <b>{{trains[id].unit}}</b>
                        </div>
                    </div>
                    <img v-if="getTrainImg(id)"
                        :src="getTrainImg(id)"
                        :style=" { position:'relative',
                        width:'100%', height:'auto'} ">
                </div>
                </v-col>     
                <v-col cols="3">
                <v-checkbox
                    v-if="!tracklessTrains.includes(id)"
                    v-model="incoming"
                    label="Inminente"
                    hide-details
                ></v-checkbox>   
                </v-col>     
            </v-row>
            <v-row>

             <v-tabs
                color="deep-purple"
                left
                v-if="!tracklessTrains.includes(id)"
                >
                <v-tab>Revisiones</v-tab>
                <v-tab>Horarios</v-tab>
                <v-tab>Movimientos</v-tab>

                <v-tab-item
                >   
                    <v-col cols="12">
                        <v-row>
                            <v-col cols='12' >    
                                <div v-if="trains[id].expected_tasks" class="expected_task">
                                    Tareas planificadas: {{expected_nice}}
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <h2>
                                    Revisiones:
                                </h2>
                            </v-col>
                        </v-row>
                        <div style="position:relative;top:3vh;">
                            <v-row no-gutters style="text-align:center" >
                                <v-col cols='1' class="table-row ">
                                    <b>Tipo</b>
                                </v-col>
                                <v-col cols='3' class="table-row" >
                                    <b>Orden de trabajo</b>
                                </v-col>
                                <v-col cols='2' class="table-row">
                                    <b>Estado</b>
                                </v-col>
                                <v-col cols='4' class="table-row" >
                                    <b>Fecha y hora finalización</b>
                                </v-col>
                                <v-col cols='1' class="table-row">
                                    <b>Eliminar</b>
                                </v-col>
                                <v-col cols='1' class="table-row">
                                    <b>Gen.Correo</b>
                                </v-col>
                            </v-row>
                            <v-row v-for="id in Object.keys(check_ups)" :id="id" :key="id.toString()" no-gutters >
                                <v-col cols='1' class="table-row ">
                                    <v-select 
                                            v-model="check_ups[id].check_up_type"
                                            :items="items.check_ups"
                                            item-text="name"
                                            item-value="value"
                                            :hide-details="true"
                                            return-value></v-select>
                                    
                                </v-col>
                                
                                <v-col cols='3' class="table-row" >
                                        <v-text-field  
                                        v-model="check_ups[id].order_num" 
                                        placeholder="Num. de orden"
                                        :hide-details="true"
                                        ></v-text-field>
                                </v-col>
                                <v-col cols='2' class="table-row">
                                    <v-select 
                                            v-model="check_ups[id].done"
                                            :items="state_items"
                                            item-text="name"
                                            item-value="value"
                                            return-value
                                            :hide-details="true"
                                            ></v-select>
                                </v-col>
                                <v-col cols='2' class="table-row">
                                    <date-picker
                                        aucomplete="off"
                                        v-model="check_ups[id].datetime_end.date"
                                        label="Fecha finalización" 
                                        :hide_details="true"
                                    ></date-picker>
                                </v-col>
                                <v-col cols='2' class="table-row">
                                    <time-widget label= "Hora finalización" v-model="check_ups[id].datetime_end.time"></time-widget>
                                </v-col>
                                <v-col cols='1' class="table-row">
                                    <v-btn @click="deleteCheckUp(id)" class = "button" color="purple" outlined>
                                        <v-icon>
                                        mdi-delete
                                        </v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols='1' class="table-row">
                                    <v-btn @click="getMailTemplate(id)" class = "button" color="purple" outlined>
                                        <v-icon>
                                        mdi-email
                                        </v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-btn @click="addCheckUp()" class = "button2" color="purple" outlined>
                                        <font-awesome-icon icon="fa-solid fa-plus fa-10x" color = "purple" />
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </div>

                        <v-row>
                            <v-col>
                                <h2>
                                    Etiquetas:
                                </h2>
                            </v-col>
                        </v-row>
                        <div style="position:relative;top:3vh;">
                            <v-row no-gutters style="text-align:center">
                                <v-col cols='8' class="table-row ">
                                    <b>Tipo</b>
                                </v-col>
                                <v-col cols='3' class="table-row">
                                    <b>Estado</b>
                                </v-col>
                                <v-col cols='1' class="table-row">
                                    <b>Eliminar</b>
                                </v-col>
                            </v-row>
                            <v-row v-for="id in Object.keys(tags)" :id="id" :key="id.toString()" no-gutters>
                                <v-col cols='8' class="table-row" >
                                    <v-select 
                                            v-model="tags[id].tag_type"
                                            :items="items.tags"
                                            item-text="name"
                                            item-value="value"
                                            return-value
                                            :hide-details="true"
                                            ></v-select>
                                </v-col>
                                <v-col cols='3' class="table-row ">
                                    <v-select 
                                            v-model="tags[id].done"
                                            :items="state_items"
                                            item-text="name"
                                            item-value="value"
                                            return-value
                                            :hide-details="true"
                                            ></v-select>
                                </v-col>
                               
                                <v-col cols='1' class="table-row ">
                                    <v-btn @click="deleteTag(id)" class = "button" color="purple" outlined>
                                       <v-icon >
                                        mdi-delete
                                        </v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-col cols="12">
                                <v-btn @click="addTag()" class = "button2" color="purple" outlined>
                                    <font-awesome-icon icon="fa-solid fa-plus fa-10x" color = "purple" />
                                </v-btn>
                            </v-col>
                        </div>
                    </v-col><br><br>
                    

                </v-tab-item>
                <v-tab-item>
                    <div style="position:relative;top:1vh">
                        <v-col cols="12" >
                            <v-row >
                                <v-col cols='5' class="table-row" >
                                    <v-row>
                                        <v-col cols='6' >
                                        LLEGADA
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols='12' >    
                                        <date-picker
                                                    aucomplete="off"
                                                    v-model="arrival.date" 
                                                    label="Fecha Llegada" 
                                                ></date-picker>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols='12' >
                                        
                                        <div v-if="trains[id].expected_arrival.date" class="expected">Fecha esperada: {{trains[id].expected_arrival.date}}</div>
                                         </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols='12' >
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols='12' >
                                            <time-widget label= "Hora llegada" v-model="arrival.time"></time-widget>
                                        </v-col>
                                        
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols='12' >
                                        <div v-if="trains[id].expected_arrival.time" class="expected">Hora esperada: {{trains[id].expected_arrival.time}}</div>
                                         </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols='1' >
                                </v-col>
                                <v-col cols='5' class="table-row" >
                                    <v-row>
                                        <v-col cols='6' >
                                        SALIDA
                                        </v-col>
                                    </v-row>
                                    
                                    <v-row no-gutters>
                                        <v-col cols='12' >
                                            <date-picker
                                                        aucomplete="off"
                                                        v-model="departure.date" 
                                                        label="Fecha Salida" 
                                            ></date-picker>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols='12' >
                                            <div v-if="trains[id].expected_departure.date" class="expected">Fecha esperada: {{trains[id].expected_departure.date}}</div>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols='12' >
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                         <v-col cols='12' >
                                            <time-widget label= "Hora salida" v-model="departure.time"></time-widget>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols='12' >
                                        <div v-if="trains[id].expected_departure.time" class="expected">Hora esperada: {{trains[id].expected_departure.time}}</div>
                                         </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols='12' >
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                        <br>
                    </div>
                </v-tab-item>
                <v-tab-item
                >  
                   <div style="position:relative;top:1vh;overflow-x: scroll;max-height:60vh;width:99%"> 
                        <v-row no-gutters v-for="(rec,i) in movements" :key="i" class="table-row">
        
                            <v-col cols="1" style="padding:5px" >
                                <b>
                                    <date-picker
                                        aucomplete="off"
                                        v-model="rec.datetime.date"
                                        :hide-details="true"

                                    ></date-picker> </b>
                            </v-col>
                            <v-col cols="1" style="padding:5px" >
                                <b> <time-widget v-model="rec.datetime.time"></time-widget> </b> 
                            </v-col>
                            <v-col cols="9" >
                                <br>
                                <p style="position:relative;bottom:0;font-size:2vh"> &nbsp; Llegada vía <b>{{trackInfo[rec.track_id].name}}</b></p>
                            </v-col>
                            <v-col cols="1">
                                <v-btn @click="deleteMove(i)" class = "button" color="purple" outlined>
                                       <v-icon>
                                        mdi-delete
                                        </v-icon>
                                   </v-btn>
                            </v-col>
                        </v-row>
                   </div>
                </v-tab-item
                >  
                </v-tabs>
                
                </v-row>
                
                <div v-if="tracklessTrains.includes(id)">
                    <v-row>
                        <v-col cols="12">
                            <div v-if="trains[id].expected_tasks" class="expected_task">
                                        Tareas planificadas: {{expected_nice}}
                            </div>
                        </v-col>
                    
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <div v-if="trains[id].expected_arrival.date && trains[id].expected_arrival.time" class="expected_task">Fecha llegada esperada: {{trains[id].expected_arrival.date}} {{trains[id].expected_arrival.time}}</div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                        <div v-if="trains[id].expected_departure.date && trains[id].expected_departure.time" class="expected_task">Fecha salida esperada: {{trains[id].expected_departure.date}} {{trains[id].expected_departure.time}}</div>
                        </v-col>
                    </v-row>
                </div>
                <br><br>
                <v-row>
                    <v-col cols='8'>
                        <v-btn @click="cancels" style="backgroundColor:#D5D5D5">Cancelar</v-btn>
                    </v-col>
                    
                    <v-col cols='4' v-if="!tracklessTrains.includes(id)">
                        <v-btn @click="saves" style="backgroundColor:#78B049">Guardar</v-btn>
                    </v-col>
                </v-row>
                <v-dialog  v-model="dialog"
                            width="500">
                     <v-card-text contenteditable="true" style="background-color:white;white-space: pre-wrap">
                        {{mail_template}} 
                     </v-card-text>
                </v-dialog>
            
    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapGetters, mapActions } from 'vuex';
import DatePicker from './DatePicker.vue'
import { client } from "@/api/client"
import TimeWidget from './TimeWidget.vue'

export default {
    data(){
        return{
            tab:null,
            text:'uololo', 
            ch_count:0,
            tg_count:0,
            datetime:null,
            id :'',
            items:{},
            check_ups:{},
            tags:{},
            arrival:{date:null,time:''},
            departure:{date:null,time:''},
            state_items:[{name:'Pendiente',value:false},{name:'Finalizado',value:true}],
            incoming:false,
            mail_template:'',
            dialog:false,
            movements:[],
        }
    },
    components:{
        DatePicker,
        TimeWidget,
    },

    computed:{
        ...mapFields('trains',['clickedTrain','trains','trainKinds','tag_types',]),
        ...mapFields('tracks',['trackInfo','getTracklessTrains','tracklessTrains']),
        ...mapGetters('trains',['getTrainImg']),
        expected_nice(){
            if(this.trains[this.id].expected_tasks){
                let text = ''
                for(let i in this.trains[this.id].expected_tasks){
                    text += this.trains[this.id].expected_tasks[i] + ', '
                }
                return text.slice(0,text.length -2)
            }
            else{
                return ''
            }
        },
        computedColorArr(){
            let text = this.arrival.time
            if (text){
                if(text.length == 5 && text[2] == ':'){
                    let hours = parseInt(text.slice(0,2))
                    let mins = parseInt(text.slice(3,5))
                    if(hours < 25 && mins < 61){
                        return 'transparent'
                    }
                }
                return '#ff9898'
            }
            else{
                return 'transparent'
            }
        },
        computedColorDep(){
            let text = this.departure.time
            if (text){
                if(text.length == 5 && text[2] == ':'){
                    let hours = parseInt(text.slice(0,2))
                    let mins = parseInt(text.slice(3,5))
                    if(hours < 25 && mins < 61){
                        return 'transparent'
                    }
                }
                return '#ff9898'
            }
            else{
                return 'transparent'
            }
        },
        
    },
  
    methods:{
        saves(){
            this.trains[this.id].incoming = this.incoming
            this.trains[this.id].check_ups = {}
            for(let i in Object.keys(this.check_ups)){
                let key = Object.keys(this.check_ups)[i]
                let checkup = this.check_ups[key]
                if(checkup.check_up_type.length){
                    this.trains[this.id].check_ups[key] = checkup
                }
            }
            this.trains[this.id].tags = {}
            for(let i in Object.keys(this.tags)){
                let key = Object.keys(this.tags)[i]
                let tag = this.tags[key]
                if(tag.tag_type.length){
                    this.trains[this.id].tags[key] = tag
                }
            }
            if(!this.arrival.date && !this.arrival.time){
                this.trains[this.id].arrival.date = null
                this.trains[this.id].arrival.time = null
            }
            else if(this.computedColorArr == 'transparent'){
                this.trains[this.id].arrival.time = this.arrival.time
                this.trains[this.id].arrival.date = this.arrival.date
            }
            if(!this.departure.date && !this.departure.time){
                this.trains[this.id].departure.date = null
                this.trains[this.id].departure.time = null
            }
            else if(this.computedColorDep == 'transparent'){
                this.trains[this.id].departure.time = this.departure.time
                this.trains[this.id].departure.date = this.departure.date
            }
            for(let i in this.movements){
                let datetimedic = this.movements[i].datetime
                this.movements[i].time = new Date(datetimedic.date + 'T' + datetimedic.time + ':00')
            }
            this.trains[this.id].track_record = this.movements
            this.updateTrain(this.id)
            this.clickedTrain = []
        },
        cancels(){
            this.clickedTrain = []
        },
        addCheckUp(){
            this.check_ups = {...this.check_ups,
                                    ['new_c'+this.ch_count]:{check_up_type: '',order_num: '',done: false,datetime_end:{date:'',time:''}}}
            this.ch_count++
        },
        addTag(){
            this.tags = {...this.tags,
                                    ['new_t'+this.tg_count]:{tag_type: '',done:false}}
            this.tg_count++
        },
        deleteCheckUp(id){
            delete this.check_ups[id]
            this.check_ups = {...this.check_ups}
            
        },
        deleteTag(id){
            delete this.tags[id]
            this.tags = {...this.tags}

        },
        deleteMove(i){
            this.movements.splice(i,1)
        },
        stateLabel(done){
            if(done){
                return 'Finalizada'
            }
            else{
                return 'Pendiente'
            }
        },
        handleInputTime(e,where,i){
            if(e.length == 2){
                if(where == 'arrival'){
                    this.arrival.time += ':'
                }
                if(where == 'departure'){
                    this.departure.time += ':'
                }
                if(where == 'check_up'){
                    this.check_ups[i].datetime_end.time += ':'
                }
                if(where == 'move'){
                    this.movements[i].datetime.time += ':'
                }
            }
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event
            var charCode = (evt.which) ? evt.which : evt.keyCode
            if ((charCode > 31 && (charCode < 48 || charCode > 58))) {
                evt.preventDefault()
            } else {
                return true
            }
        },
        getMailTemplate(id){
            let chup = this.check_ups[id]
            let unit = this.trains[this.id].unit.toString()
            let train_name = this.trainKinds[this.trains[this.id].kind].name.slice(1) + '-'+('00'+unit).slice(('00'+unit).length-3,('00'+unit).length)
            client.get('api/get_mail_checkup_text/',{params:{'revision':this.trainKinds[this.trains[this.id].kind].check_ups[chup.check_up_type].name,'tren':train_name,'fecha':chup.datetime_end.date,'hora':chup.datetime_end.time}}).then((response)=>{
                this.dialog = true
                this.mail_template = response.data
            })
        },
        ...mapActions('trains',['updateTrain']),
    },
    created(){
        this.id = this.clickedTrain[0]
        let train = this.trains[this.id]
        this.incoming = train.incoming
        //omplim items (posibles checkups i tags en format v-select)
        this.items['check_ups'] = []
        this.items['tags'] = []
        let ch_ids  = Object.keys(this.trainKinds[train.kind].check_ups)
        let tag_ids = Object.keys(this.tag_types)
        for(let i in ch_ids){
            let id = ch_ids[i]
            this.items.check_ups.push({value:id, name: this.trainKinds[train.kind].check_ups[id].name })
        }  
        for(let i in tag_ids){
            let id = tag_ids[i]
            this.items.tags.push({value:id, name: this.tag_types[id].name })
        }
        //omplim el values, on es modelara la informacio per posteriorment guardar-la (o no)
        for(let i in Object.keys(this.trains[this.id].check_ups)){
            let id = Object.keys(this.trains[this.id].check_ups)[i]
            this.check_ups[id] = this.trains[this.id].check_ups[id]
        }
        for(let i in Object.keys(this.trains[this.id].tags)){
            let id = Object.keys(this.trains[this.id].tags)[i]
            this.tags[id] = this.trains[this.id].tags[id]
        }       
        if(this.trains[this.id].arrival.date){
            this.arrival.date = this.trains[this.id].arrival.date
        }
        if(this.trains[this.id].arrival.time){
            this.arrival.time = this.trains[this.id].arrival.time
        }
        if(this.trains[this.id].departure.date){
            this.departure.date = this.trains[this.id].departure.date
        }
        if(this.trains[this.id].departure.time){
            this.departure.time = this.trains[this.id].departure.time
        }
        this.movements = this.trains[this.id].track_record
    }
}
</script>

<style scoped>

.modal2 {
 position: fixed;
 top: 50%;
 left: 50%;
 transform: translate(-50%,-50%);
 z-index: 10;
 
 width: 100%;
 max-width: 60vw;
 background-color: #FFF;
 border-radius: 16px;
 border:7px solid rgb(66, 66, 66);
 
 padding: 40px;
}
.button{
    border-radius: 5%;
    height:100%;
    left:10%;
    top:10%;
}
.button2{
    border-radius: 5%;
    left: 40%
}
.dot {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: orange;
  border-radius: 50%;
  display: inline-block;
  position:absolute;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-style: bald;
  z-index:15;
}
.top_train{
    position:relative;
    top:4%;
    left:10%;
    width:200px;
    height:auto;
}
.table-row{
    border-style:solid;
    border-color:#663399;
    border-width: thin;

}
.expected{
    position:absolute;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-style: italic;
    font-size:0.7vw;
    color: darkred;
}
.expected_task{
    position:relative;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-style: italic;
    font-size:1vw;
    color: darkred;
}
</style>
