<template>
    <div>
        <div
            :id="id"
            class="track"
            @dragenter.prevent = "dragEnter"
            @dragleave.prevent = "dragLeave"
            @dragover.prevent 
            @drop.prevent="drop"
            @dblclick.stop="addNote"
            @click="clickTrack"
            :style="{backgroundColor: dynamic_track_color, height: size['height'], width: size['width'],
                    boxShadow: dynamic_style.shadow, outline:dynamic_style.border}"
        >
            <slot/>
        </div>
        <img v-if="trackInfo[id].has_tension" src="../assets/tensionn.svg" class = "warning2" 
        :style="{opacity:dynamic_style.opacity,width: (parseInt(size['height'])/1.8).toString()+'px', height:'auto', left: '-'+(parseInt(size['height'])/1.6).toString()+'px',top:(parseInt(size['height'])/5).toString()+'px'}"
            @click="warn" draggable="false" @dblclick.stop>
        
    </div>
</template>

<script>
import {mapActions,mapGetters} from 'vuex'
import { mapFields } from 'vuex-map-fields';

export default {
    data(){
    return{
        actual_color:'rgba(100, 51, 153,0.05)',
    }
  },
    props: {
        id:String,
        size:Object,
        color:String,
        editorMode:{type:Boolean,default:false},
        },
    name:'Track',
    methods: {
        async drop(){ 
            if(this.dragging.length == 1){
                let dropped = this.dragging[0]
                this.loading = true
                this.moving = {train:dropped.train, track:dropped.track}
                let origin = dropped.track
                if(origin == 'sidebar'){
                    this.deleteTracklessTrain(dropped.train)
                }
                else {
                    this.deleteTrain({train:dropped.train, track:dropped.track });
                }
                try{
                    await this.addTrain({train:dropped.train , track:this.id})
                    this.get_train_data(dropped.train)
                }
                catch(err){
                    console.error(err)
                }
                this.actual_color = 'rgba(100, 51, 153,0.05)'
                this.loading = false
                this.moving = {}
            }
        },
        dragEnter(){
            if(this.dragging.length == 1){
                this.actual_color = 'rgba(110, 51, 153,0.5)'
            } 
        },
        dragLeave(){
            if(this.dragging.length == 1){
                this.actual_color = 'rgba(100, 51, 153,0.05)'
            }
        },
        warn(){
            this.warnTrack(this.id)
            
        },
        addNote(event){
            this.$emit('addnote', [event.layerX,event.layerY,this.id])
            
        },
        ...mapActions('trains',["clearDrag",'get_train_data']),
        ...mapActions('tracks',["moveTrain","addTrain",'warnTrack','deleteTrain','deleteTracklessTrain']),
        clickTrack(){
            if(this.clickedTrack.length == 0 && this.editorMode){
                this.clickedTrack.push(this.id)
            }
        }
    },
    computed:{
        dynamic_track_color(){
            if(this.isFull && !this.editorMode){
                return 'rgb(255,0,0,1)'
            }
            else{
                return this.actual_color
            }
        },
        dynamic_style(){
            let border = '0vw solid #ffd000'
            let opacity = 0.3
            let shadow = '0px 0px 0px 0px rgb(255, 208, 0)'
            if(this.trackInfo[this.id].has_tension && this.trackTension[this.id]){
                opacity = 1, 
                shadow = '0px 0px 1vw 0px rgb(255, 208, 0)'
                border = '0.15vw solid #ffd000'
            }
            if(this.dragging.length == 1){
                border = '0.2vw dashed #902A37'
            }
            return {opacity:opacity,shadow:shadow,border:border}
        },
        isFull(){
            if(this.trackInfo[this.id].length){
                let trains_len = 0
                for(let i in this.trackContent[this.id]){
                    let train_id = this.trackContent[this.id][i]
                    if(!this.trains[train_id].incoming){
                        let kind = this.trains[train_id].kind  
                        let len = this.trainKinds[kind].length  
                        trains_len += len
                    }
                }
                if(this.trackInfo[this.id].length - trains_len < 0){
                    return true
                }
            }
            return false
        },
        ...mapFields('trains',['dragging','trains','trainKinds']),
        ...mapFields('tracks',['trackContent', 'trackInfo', 'clickedTrack','trackTension','moving']),
        ...mapFields(['loading']),
        ...mapGetters('tracks',['getTracklessTrains']),
    },
    mounted(){
        this.actual_color = this.color
    }
}
</script>

<style scoped>
.track {
  position:absolute;  
  display: flex;
  flex-direction: row;
  padding: 0.1vw;
  top: 0px;
  left:0px;
  border-radius: 0.3vw;
  box-sizing: border-box;
}

.warning2 {
    position:absolute;
    width:auto;
}

</style>